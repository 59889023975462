import DataManager from './dtm';
import { fbDatabase } from '../firebase/firebase';


class ClienteDB extends DataManager {

  state = {
    collection: 'clientes',
    orderBy: ['razaoSocial', 'nomeFantasia'], // usado na classe pai
  }

  async get(orderByNomeFantasia) {

    let query = fbDatabase
      .collection(this.state.collection)

    if (orderByNomeFantasia) {
      query = query.orderBy('nomeFantasia');
    } else {
      query = query
        .orderBy('razaoSocial')
        .orderBy('nomeFantasia');
    }

    const snapshot = await query.get();

    const data = snapshot.docs.map((item) => ({
      key: item.id,
      ...item.data(),
      ativoStr: item.data().ativo ? 'Sim' : 'Não',
    }));

    return data;
  }

  async getByCNPJ(cnpj) {
    const data = await this.getByParamValue('cnpj', cnpj);
    return data;
  }

  async getByEmail(email) {
    const data = await this.getByParamValue('email', email);
    if (data !== undefined) {
      data[0].ativoStr = data[0].ativo ? 'Sim' : 'Não';
    }
    return data;
  }

  async monitorarClientes(callBack) {

    const monitoramentoTempoReal = fbDatabase.collection("clientes")
      .where("ativo", "==", true)
      .onSnapshot((querySnapshot) => {
        if (callBack) {
          callBack(querySnapshot);
        }
      });

    return monitoramentoTempoReal;
  }
}

const clienteDB = new ClienteDB();
export default clienteDB;
