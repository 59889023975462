import { Component } from 'react';
import { fireBase, UPDATE_USER_URL } from '../../firebase/firebase';

import { message } from 'antd';

class UserAdmin extends Component {

  async createEmail(email, password) {
    try {
      const obj = await fireBase.auth().createUserWithEmailAndPassword(email, password);
      if (!obj) {
        message.error(`Erro ao criar o usuário com o email ${email}`);
        this.setState({ loadingButton: false });
        return undefined;
      }
      return obj;
    } catch (error) {
      console.log(error.code);
      return undefined;
    }
  }

  async activeOrDeactiveUser(email, active) {
    return new Promise(async (resolve, reject) => {
      const token = await fireBase.auth().currentUser.getIdToken(true);
      const url = UPDATE_USER_URL;
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': `bearer ${token}`,
          'authusertoken': true,
          'usermail': email,
          'ativo': active
        },
      }).then((response) => response.json())
        .then((responseData) => {
          if (responseData.code === 200) {
            resolve(responseData);
          }
          else {
            reject(responseData);
          }
        });
    });
  }

  async changeUserMail(oldEmail, newEmail) {
    return new Promise((resolve, reject) => {
      const url = 'UPDATE_MAIL_USER_URL';
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': 'WO-3290JFXCIMAN',
          'oldemail': oldEmail,
          'newemail': newEmail,
        },
      }).then((response) => response.json())
        .then((responseData) => {
          if (responseData.code === 200) {
            resolve(responseData);
          }
          else {
            reject(responseData);
          }
        });
    });
  }
}

const userAdmin = new UserAdmin();
export default userAdmin
