import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { getEmpresas, getVendedores } from './VendedorF';

import {
  Avatar,
  Table,
  Tooltip,
  Icon,
  Button,
  Checkbox,
  Input,
  Select,
  notification,
  Modal
} from 'antd';

import PageLayout from '../../components/layout/PageLayout';
import VendedorCRUD from '../../components/cruds/vendedores/VendedorCRUD';
import clienteDB from '../../dataManager/dtmCliente';
import mensagem from '../../components/messages/message';
import listObj from '../../components/listSearch/listSearch';

import './Vendedor.css';
import 'antd/dist/antd.css';


const { Search } = Input;
const { Option } = Select;
let interval;


class Vendedor extends Component {
  state = {
    loading: false,
    isSearching: false,
    blockLoadMore: false,
    checked: undefined,
    gestorId: undefined,
    empresaId: undefined,
    vendedores: [],
    vendedoresOriginal: [],
    vendedoresInativos: [],
    vendedoresInativosOriginal: []
  }

  constructor(props) {
    super(props);

    this.headerVendedorElement = React.createRef();

    this.applyFilter = this.applyFilter.bind(this);
    this.updateList = this.updateList.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.onSelectClienteChange = this.onSelectClienteChange.bind(this);
    this.onSelectEmpresaChange = this.onSelectEmpresaChange.bind(this);
    this.getCheckboxState = this.getCheckboxState.bind(this);
    this.checkboxUpdate = this.checkboxUpdate.bind(this);
    this.editarVendedorElement = this.editarVendedorElement.bind(this);
  }

  applyFilter(text) {
    this.setState({ loading: true });

    const {
      vendedoresOriginal,
      vendedoresInativosOriginal
    } = this.state;

    if (text === '') {
      this.setState({
        vendedores: vendedoresOriginal,
        vendedoresInativos: vendedoresInativosOriginal,
        isSearching: false,
        loading: false
      });

      return;
    }

    const keys = [
      'apelido',
      'nome',
      'empresa.nome',
      'ativoStr'
    ];

    const ativosResult = listObj.search(vendedoresOriginal, text, keys);
    const inativosResult = listObj.search(vendedoresInativosOriginal, text, keys);

    this.setState({
      vendedores: ativosResult,
      vendedoresInativos: inativosResult,
      isSearching: true,
      loading: false
    });

    if (ativosResult.length === 0 && inativosResult.length === 0) {
      return {
        message: 'Resultado não Encontrado',
        description: 'Sua busca não retornou nenhum resultado. Por favor, redefine sua busca.'
      };
    } else {
      return {
        message: 'Busca finalizada',
        description: 'Caso não encontre o vendedor desejado, por favor, refine sua busca.'
      };
    }
  }

  updateList(record) {
    const {
      vendedores,
      vendedoresInativos,
      empresaId
    } = this.state;

    let registroEncontrado = false;

    this.state.vendedores.forEach((item, index) => {
      if (item.key === record.key) {
        if (record.ativo !== item.ativo) {
          vendedores.splice(index, 1);

          if (vendedoresInativos.length) {
            vendedoresInativos.push(record);
          }
        } else {
          vendedores[index] = record;
        }

        if (empresaId !== record.empresa.id) {
          vendedores.splice(index, 1);
        }

        registroEncontrado = true;
      }
    });

    if (vendedoresInativos.length && !registroEncontrado) {
      this.state.vendedoresInativos.forEach((item, index) => {
        if (item.key === record.key) {
          if (record.ativo !== item.ativo) {
            vendedoresInativos.splice(index, 1);
            vendedores.push(record);
          } else {
            vendedores[index] = record;
          }

          if (empresaId !== record.empresa.key) {
            vendedoresInativos.splice(index, 1);
          }

          registroEncontrado = true;
        }
      });
    }

    if (empresaId === record.empresa.id) {
      if (!registroEncontrado && record.ativo) {
        vendedores.push(record);
      } else if (vendedoresInativos.length && !registroEncontrado) {
        vendedoresInativos.push(record);
      }
    }

    this.setState({
      vendedores,
      vendedoresInativos,
      vendedoresOriginal: vendedores,
      vendedoresInativosOriginal: vendedoresInativos
    });
  }

  async loadMore() {
    this.setState({ loading: true });

    const {
      gestorId,
      empresaId,
      checked,
      vendedores,
      vendedoresInativos
    } = this.state;

    const limite = checked ? 25 : 50;
    let dataAtivos = [];
    let dataInativos = [];

    if (vendedores.length) {
      const lastDocIdAtivos = vendedores[vendedores.length - 1].key;
      dataAtivos = await getVendedores(gestorId, empresaId, true, lastDocIdAtivos, limite);
    }

    if (vendedoresInativos.length) {
      const lastDocIdInativos = vendedoresInativos[vendedoresInativos.length - 1].key;
      dataInativos = await getVendedores(gestorId, empresaId, false, lastDocIdInativos, limite);
    }

    if (dataAtivos.length === 0 && dataInativos.length === 0) {
      mensagem.avisar('Não existe mais vendedores a serem carregados');
      this.setState({ loading: false, blockLoadMore: true });
      return;
    }

    const newTableAtivos = dataAtivos.length > 0 ? vendedores.concat(dataAtivos) : vendedores;
    const newTableInativos = dataInativos.length > 0 ? vendedoresInativos.concat(dataInativos) : vendedoresInativos;

    this.setState({
      loading: false,
      vendedores: newTableAtivos,
      vendedoresOriginal: newTableAtivos,
      vendedoresInativos: newTableInativos,
      vendedoresInativosOriginal: newTableInativos
    });
  }

  onSelectClienteChange(value) {
    this.setState({ loading: true });

    setTimeout(() => {
      this.setState({
        gestorId: value,
        vendedores: [],
        vendedoresOriginal: [],
        vendedoresInativos: [],
        vendedoresInativosOriginal: [],
        empresaId: undefined,
        loading: false,
        isSearching: false,
        blockLoadMore: false,
        checked: false
      });
    }, 550);
  }

  async onSelectEmpresaChange(value) {
    this.setState({ loading: true });

    const { gestorId } = this.state;
    const vendedores = await getVendedores(gestorId, value, true);

    this.setState({
      vendedores,
      vendedoresOriginal: vendedores,
      vendedoresInativos: [],
      vendedoresInativosOriginal: [],
      empresaId: value,
      loading: false,
      isSearching: false,
      blockLoadMore: false,
      checked: false
    })
  }

  getCheckboxState(checked) {
    this.setState({ checked });
    this.checkboxUpdate();
  }

  async checkboxUpdate() {
    this.setState({ loading: true });

    const {
      checked,
      gestorId,
      empresaId,
      vendedoresInativosOriginal
    } = this.state;

    if (checked && vendedoresInativosOriginal.length === 0) {
      const vendedoresInativos = await getVendedores(gestorId, empresaId, false);

      this.setState({
        vendedoresInativos,
        vendedoresInativosOriginal: vendedoresInativos,
        blockLoadMore: false
      });
    }

    this.setState({ loading: false });
  }

  async editarVendedorElement(record) {
    this.headerVendedorElement.current.editarVendedor(record);
  }

  render() {
    const columns = [
      {
        title: 'Foto',
        dataIndex: 'avatar',
        key: 'avatar',
        width: 90,
        render: (avatar) => (
          <span>
            <Avatar size={52} src={avatar} />
          </span>
        ),
      },
      {
        title: 'Apelido',
        dataIndex: 'apelido',
        key: 'apelido',
      },
      {
        title: 'Nome Completo',
        dataIndex: 'nome',
        key: 'nome',
      },
      {
        title: 'Ativo?',
        dataIndex: 'ativoStr',
        key: 'ativo',
      },
      {
        title: '',
        width: '30px',
        key: 'action',
        render: (text, record) => (
          <span>
            <Tooltip placement='topLeft' title='Editar Cadastro'>
              <a onClick={() => this.editarVendedorElement(record)}>
                <Icon type='edit' />
              </a>
            </Tooltip>
          </span>
        ),
      },
    ];

    return (
      <PageLayout selectItem={'vendedores'}>
        <Table
          title={() => (
            <HeaderVendedor
              ref={this.headerVendedorElement}
              applyFilter={this.applyFilter}
              updateList={this.updateList}
              getCheckboxState={this.getCheckboxState}
              onSelectClienteChange={this.onSelectClienteChange}
              onSelectEmpresaChange={this.onSelectEmpresaChange}
            />
          )}

          footer={() => (
            <div className='footerContainer'>
              <Button
                type='primary'
                loading={this.state.loading}

                disabled={
                  this.state.blockLoadMore ||
                  this.state.isSearching ||
                  !this.state.gestorId ||
                  !this.state.empresaId
                }

                onClick={this.loadMore}
              >
                Carregar mais Vendedores
              </Button>

              <div style={{ marginTop: '0.625rem' }}>
                Quantidade de Vendedores: {this.state.vendedoresOriginal.length + (this.state.checked ? this.state.vendedoresInativosOriginal.length : 0)}
              </div>
            </div>
          )}

          dataSource={
            this.state.checked || this.state.isSearching ?
              this.state.vendedores.concat(this.state.vendedoresInativos) :
              this.state.vendedores
          }

          columns={columns}
          loading={this.state.loading}
          pagination={false}
        />
      </PageLayout>
    )
  }
}

class HeaderVendedor extends Component {
  state = {
    visible: false,
    checked: false,
    gestorId: undefined,
    empresaId: undefined,
    optionsClientes: [],
    optionsEmpresas: [],
    empresas: []
  }

  constructor(props) {
    super(props);

    this.novoVendedorElement = React.createRef();

    this.loadOptionsClientes = this.loadOptionsClientes.bind(this);
    this.loadOptionsEmpresas = this.loadOptionsEmpresas.bind(this);
    this.novoVendedor = this.novoVendedor.bind(this);
    this.editarVendedor = this.editarVendedor.bind(this);
    this.filterTable = this.filterTable.bind(this);
    this.resetTime = this.resetTime.bind(this);
    this.onCheck = this.onCheck.bind(this);
    this.onSelectClienteChange = this.onSelectClienteChange.bind(this);
    this.onSelectEmpresaChange = this.onSelectEmpresaChange.bind(this);
  }

  componentDidMount() {
    this.loadOptionsClientes();
  }

  async loadOptionsClientes() {
    const clientes = await clienteDB.get(true);
    const optionsClientes = [];

    for (let i = 0; i < clientes.length; i++) {
      const {
        key,
        nomeFantasia,
        email
      } = clientes[i];

      optionsClientes.push(
        <Option key={i} value={key}>
          {`${nomeFantasia} | ${email}`}
        </Option>
      );
    }

    this.setState({ optionsClientes });
  }

  async loadOptionsEmpresas(gestorId) {
    const empresas = await getEmpresas(gestorId);
    const optionsEmpresas = [];

    for (let i = 0; i < empresas.length; i++) {
      const {
        key,
        nomeFantasia
      } = empresas[i];

      optionsEmpresas.push(
        <Option key={i} value={key}>
          {nomeFantasia}
        </Option>
      );
    }

    this.setState({
      empresas,
      optionsEmpresas
    });
  }

  novoVendedor() {
    const { gestorId } = this.state;
    const record = { gestorId };

    this.props.applyFilter('');
    this.novoVendedorElement.current.show(false, record);
  }

  editarVendedor(record) {
    this.props.applyFilter('');
    this.novoVendedorElement.current.show(true, record);
  }

  async filterTable(obj) {
    clearInterval(interval);

    const text = typeof obj === 'object' ? obj.target.value : obj;

    const searchWarning = await this.props.applyFilter(text);

    if (searchWarning) {
      notification.open(searchWarning);
    }
  }

  async resetTime(obj) {
    clearInterval(interval);
    interval = setInterval(this.filterTable, 3000, obj.target.value);
  }

  async onCheck(e) {
    await this.setState({ checked: e.target.checked });
    this.props.getCheckboxState(this.state.checked);
  }

  async onSelectClienteChange(value) {
    this.setState({
      gestorId: value,
      empresaId: undefined,
      checked: false
    });

    this.props.onSelectClienteChange(value);
    await this.loadOptionsEmpresas(value);
  }

  async onSelectEmpresaChange(value) {
    this.setState({ empresaId: value, checked: false });
    this.props.onSelectEmpresaChange(value);
  }

  render() {
    return (
      <div className='headerVendedor'>
        <NovoVendedor
          ref={this.novoVendedorElement}
          updateList={this.props.updateList}
          empresas={this.state.empresas}
        />

        <div className='selectContainer'>
          <label>
            Cliente:
          </label>

          <Select
            showSearch
            placeholder='Selecionar o cliente'
            optionFilterProp='children'

            value={this.state.gestorId}
            onChange={this.onSelectClienteChange}

            style={{ marginLeft: '0.625rem', width: '100%' }}
          >
            {this.state.optionsClientes}
          </Select>
        </div>

        <div className='selectContainer' id='selectEmpresa'>
          <label>
            Empresa:
          </label>

          <Select
            showSearch
            placeholder='Selecionar a empresa'
            optionFilterProp='children'

            value={this.state.empresaId}
            disabled={!this.state.gestorId}
            onChange={this.onSelectEmpresaChange}

            style={{ marginLeft: '0.625rem', width: '100%' }}
          >
            {this.state.optionsEmpresas}
          </Select>
        </div>

        <Button
          type='primary'

          onClick={this.novoVendedor}
          disabled={!this.state.gestorId || !this.state.empresaId}
          style={{ marginLeft: '0.625rem' }}
        >
          <Icon className='icon' type='plus' />
          Novo Vendedor
        </Button>

        <Search
          allowClear
          placeholder='Procurar Vendedor'

          onSearch={this.filterTable}
          onChange={this.resetTime}
          disabled={!this.state.gestorId || !this.state.empresaId}

          style={{ marginLeft: '0.625rem', width: '15%' }}
        />

        <Checkbox
          checked={this.state.checked}
          onChange={this.onCheck}
          disabled={!this.state.gestorId || !this.state.empresaId}

          style={{ marginLeft: '0.625rem' }}
        >
          Listar Inativos
        </Checkbox>
      </div>
    )
  }
}

class NovoVendedor extends Component {
  state = {
    record: [],
    visible: false,
    confirmLoading: false,
    editMode: false
  }

  constructor(props) {
    super(props);

    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  async show(editMode, record) {
    if (!record) {
      record = [];
    }

    this.setState({
      editMode,
      record,
      visible: true,
      confirmLoading: false,
    });
  }

  handleOk(record) {
    this.props.updateList(record);
    this.setState({ visible: false });
  }

  async handleCancel() {
    if (!(await mensagem.confirmar('Cancelar alterações?'))) return;
    this.setState({ visible: false });
  }

  render() {
    return (
      <Modal
        title='Vendedor'

        destroyOnClose={true}
        centered={true}
        footer={null}
        closable={false}
        visible={this.state.visible}
        confirmLoading={this.state.confirmLoading}

        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        <VendedorCRUD
          editMode={this.state.editMode}
          record={this.state.record}
          empresas={this.props.empresas}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
        />
      </Modal>
    )
  }
}

export default withRouter(Vendedor);

