import { message } from 'antd';
import { fireBase } from '../../firebase/firebase';
import { authenticate, currentUser } from '../../components/auth/auth-provider';
import usuarioDB from '../../dataManager/dtmUsuario';
import API from '../../services/api/api';

const api = new API();


export async function autenticar(email, senha) {
  try {
    const obj = await fireBase.auth().signInWithEmailAndPassword(email, senha);
    let usuario = await usuarioDB.getByUserId(obj.user.uid);

    // Controle de acesso
    // O controle de acesso irá registrar o novo idToken
    const { uid, refreshToken } = obj.user;
    await api.accessControl(uid, refreshToken);

    // Se não encontrar o usuário, verifico se é um beneficiário.
    // Caso não seja, sei que estou lidando com um Gestor
    if (!usuario || !usuario.is4CAdmin) {
      fireBase.auth().signOut();
      throw { code: 'auth/user-disabled' };
    }

    // Salvo em cache
    authenticate(obj.user);
    return true;
  } catch (error) {
    debugger;
    // Handle Errors here.
    if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password' || error.code === 'auth/user-disabled') {
      message.error('Senha ou email incorretos. Verifique.');
    }

    if (error.code === 'auth/too-many-requests') {
      message.error('Você excedeu o número de tentativas de login. Por favor, tente novamente mais tarde.');
    }

    return false;

  }
}

export async function redefinePassword(email) {
  return new Promise((resolve) => {
    fireBase.auth().sendPasswordResetEmail(email)
      .then(function () {
        resolve({
          success: true,
          message: 'Ok',
        });
      }).catch(function (error) {
        if (error.code === "auth/invalid-email") {
          error.message = 'Email inválido';
        }
        // An error happened.
        resolve({
          success: false,
          message: error.message,
        });
      });
  });
}

export function redirectJustIfUserIsAuthenticated() {
  const user = currentUser();
  if (user) {
    this.props.history.push('/dashboard');
  }
}
