import API from '../../services/api/api';

const api = new API();


export async function getEmpresas(gestorId, lastDocId) {
  let empresas = [];
  const limite = 100;

  empresas = await api.getEmpresas(gestorId, limite, lastDocId);

 const empresasUpdt = empresas.map(item => {
    if (item.ativo) {
      return { ...item, ativoStr: 'Sim' };
    } else {
      return { ...item, ativoStr: 'Não' };
    }
  });

  return empresasUpdt;
}
