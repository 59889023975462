import React, { Component } from "react";
import { withRouter } from 'react-router'
import './Dashboard.css';
import PageLayout from '../../components/layout/PageLayout';
import { Spin } from 'antd';
import RealTime from "../../components/DashboardV2/RealTime/RealTime";


const VERDE = '#32a852';
const LARANJA = '#ebb134';
const ROXO = '#a234eb';

class Dashboard extends Component {

  state = {
    loading: false,
    data: {
      labels: [],
      datasets: []
    },
    valores: {
      debito: 'Calculando...',
      credito: 'Calculando...',
      creditoPre: 'Calculando...',
    }
  }

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <PageLayout selectItem={'dashboard'}>
          <div className="divDashLayout">
            <RealTime />
          </div>

        </PageLayout>
      </div >
    );
  }
}

export default withRouter(Dashboard);
