import axios from './axios';


export default class API {
  async cadastrarEmpresa(item) {
    const route = '/empresa';
    const params = { ...item };

    const response = await request(
      'post',
      route,
      params
    );

    return response;
  }

  async cadastrarGerente(item) {
    const route = '/gerente';
    const params = { ...item };

    const response = await request(
      'post',
      route,
      params
    );

    return response;
  }

  async cadastrarVendedor(item) {
    const route = '/vendedor';
    const params = { ...item };

    const response = await request(
      'post',
      route,
      params
    );

    return response;
  }

  async atualizarEmpresa(updateList, key) {
    const route = '/empresa';

    const params = {
      updateList,
      key
    };

    const response = await request(
      'put',
      route,
      params
    );

    return response;
  }

  async atualizarGerente(updateList, key, atualizarAtivo) {
    const route = '/gerente';

    const params = {
      updateList,
      key,
      atualizarAtivo
    };

    const response = await request(
      'put',
      route,
      params
    );

    return response;
  }

  async atualizarVendedor(updateList, key) {
    const route = '/vendedor';

    const params = {
      updateList,
      key
    };

    const response = await request(
      'put',
      route,
      params
    );

    return response;
  }

  async getEmpresas(gestorId, limite, lastDocId) {
    const route = '/empresa';

    const params = {
      gestorId,
      limite,
      lastDocId
    };

    const response = await request(
      'get',
      route,
      params
    );

    return response;
  }

  async getEmpresasGerente(gerenteId) {
    const route = '/gerente/empresas';
    const params = { gerenteId };

    const response = await request(
      'get',
      route,
      params
    );

    return response;
  }

  async getGerentes(gestorId, ativo, limite, lastDocId) {
    const route = '/gerente';

    const params = {
      gestorId,
      ativo,
      limite,
      lastDocId
    };

    const response = await request(
      'get',
      route,
      params
    );

    return response;
  }

  async getVendedores(gestorId, empresaId, ativo, limite, lastDocId) {
    const route = '/vendedor';

    const params = {
      gestorId,
      empresaId,
      ativo,
      limite,
      lastDocId
    };

    const response = await request(
      'get',
      route,
      params
    );

    return response;
  }

  async getVendedorById(vendedorId) {
    const route = '/vendedor/getById';
    const params = { vendedorId };

    const response = await request(
      'get',
      route,
      params
    );

    return response;
  }

  async accessControl(uid, refreshToken) {
    try {
      const route = `/accessControl/${uid}/${refreshToken}`;
      await request('post', route, {});
    } catch (error) {
      console.log(error);
    }
  }

  async checkRefreshToken(uid, refreshToken) {
    try {
      const route = `/accessControl/check/${uid}/${refreshToken}`;

      const response = await request('post', route, {});
      return response.revoked;
    } catch (error) {
      console.log(error);
    }
  }
}

function request(method, route, params) {
  return new Promise(async (resolve, reject) => {
    let response;

    try {
      response = method === 'get' ?
        await axios[method](route, { params: { ...params } }) :
        await axios[method](route, { ...params });

      const res = method === 'get' ? response.data.result : response.data;

      resolve(res);
    } catch (error) {
      response = errorHandler(error);

      if (response) {
        resolve(response);
      } else {
        reject(error);
      }
    }
  })
}

function errorHandler(error) {
  console.log(error);

  if (error.response.data.code === 400) {
    return error.response.data;
  }

  return undefined;
}
