import firebase from "firebase";

const DEV_PROJECT = false;
const USE_LOCAL_API = false;

const ORIG_FILA_URL = "https://fila.bluve.com.br/";
const STAGING_FILA_URL = "https://staging.fila.bluve.com.br/";

const ORIG_API_URL = "https://bluve.com.br/api/v1";
const STAGING_ORIG_API_URL = "https://staging.bluve.com.br/api/v1";

let config;

if (!DEV_PROJECT) {
  config = {
    apiKey: "AIzaSyD6382EgeegLjTkKkXmMBnVxoDjamSTk9c",
    authDomain: "sira-41b32.firebaseapp.com",
    databaseURL: "https://sira-41b32.firebaseio.com",
    projectId: "sira-41b32",
    storageBucket: "sira-41b32.appspot.com",
    messagingSenderId: "322901096086",
    appId: "1:322901096086:web:0fc268bc95a5545d9774b7",
    measurementId: "G-QZM4CS2LWB",
  };
} else {
  config = {
    apiKey: "AIzaSyBb8MxslSLv4AnOhWFH2vSJuxMoEi5EeSk",
    authDomain: "bluve-4c-teste.firebaseapp.com",
    databaseURL: "https://bluve-4c-teste.firebaseio.com",
    projectId: "bluve-4c-teste",
    storageBucket: "bluve-4c-teste.appspot.com",
    messagingSenderId: "219758613774",
    appId: "1:219758613774:web:369b210dbaf37132c6735f",
    measurementId: "G-ZCPXRYKP6S",
  };
}
export const fireBase = firebase.initializeApp(config);

// Só chamo o Analytics em ambiente oficial! ;-)
if (!DEV_PROJECT) {
  firebase.analytics();
}

function getProject() {
  const project = DEV_PROJECT ? 'bluve-4c-teste' : 'sira-41b32';
  if (USE_LOCAL_API) {
    return `http://localhost:5001/${project}/us-central1/api/v2`;
  }
  return `https://us-central1-${project}.cloudfunctions.net/api/v2`;
}


export const fbDatabase = firebase.firestore();
export const fbFirestore = firebase.firestore;
export const Timestamp = firebase.firestore.Timestamp;
export const FILA_URL = !DEV_PROJECT ? ORIG_FILA_URL : STAGING_FILA_URL;
export const API_URL = !DEV_PROJECT ? ORIG_API_URL : STAGING_ORIG_API_URL;
export const KBA_PROJECT = !DEV_PROJECT ? 'bluve' : 'bluve-dev';
export const UPDATE_EMAIL_URL = API_URL + "/cadastro/update";
export const MITTE_URL = "https://push-dot-montu-web.uc.r.appspot.com/communication/mitte-pro/send-paid-mail";
export const UPDATE_USER_URL = `${getProject()}/user/status/active/change`;
export const ROUTER_API_BLUVE = getProject().replace('api/v2', 'apiBluve');
