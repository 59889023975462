import DataManager from './dtm';
import { fbDatabase, fbFirestore } from '../firebase/firebase';


class ConfiguracaoDB extends DataManager {

  state = {
    collection: 'configuracoes',
    orderBy: [], // usado na classe pai
  }

  async getConfiguracao(gestorId) {
    const snapshot = await fbDatabase.collection(this.state.collection)
      .where('gestorId', '==', gestorId)
      .get();

    /** X-TUDO */
    let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmConfiguracaoJSX-ConfiguracaoDB-getConfiguracao');
    xRef.set({
      collection: 'configuracao',
      lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
      count: fbFirestore.FieldValue.increment(snapshot.size),
      lastIncrement: snapshot.size
    }, { merge: true }).catch(error => console.error(error));

    return snapshot.empty ? undefined : snapshot.docs.map((doc) => doc.data());
  }
}

const configuracaoDB = new ConfiguracaoDB();
export default configuracaoDB;
