import API from '../../services/api/api';

const api = new API();


export async function getVendedores(gestorId, empresaId, ativo, lastDocId, limite) {
  let vendedores = [];
  limite = limite ?? 50;

  vendedores = await api.getVendedores(gestorId, empresaId, ativo, limite, lastDocId);

  return vendedores;
}

export async function getEmpresas(gestorId) {
  let empresas = [];
  empresas = await api.getEmpresas(gestorId);
  empresas = empresas.filter(empresa => empresa.ativo === true);
  empresas = empresas.sort((a, b) => a.nomeFantasia.localeCompare(b.nomeFantasia));

  return empresas;
}
