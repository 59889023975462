import React from 'react';
import { Form, Input, Button, Icon, message } from 'antd';
import './Login.css';
import { autenticar, redirectJustIfUserIsAuthenticated, redefinePassword } from './LoginF'
import history from '../../history';
import mensagem from '../../components/messages/message';

const params = new URLSearchParams(window.location.search);

class Login extends React.Component {

  state = {
    passType: 'password',
    passClass: 'zmdi zmdi-eye',
    loading: false,
  }

  constructor(props) {
    super(props);
    this.autenticar = autenticar.bind(this);
    this.redirectJustIfUserIsAuthenticated = redirectJustIfUserIsAuthenticated.bind(this);
    this.esqueciSenhaOnClick = this.esqueciSenhaOnClick.bind(this);
    this.redirectJustIfUserIsAuthenticated();
  };

  componentDidMount() {
    // Exibir mensagem de sessão encerrada caso sessionRevoked=true
    if (params.get('sessionRevoked')) {
      mensagem.avisar('Sessão encerrada devido a login em outro dispositivo.');
    }
  }

  esqueciSenhaOnClick() {
    const email = this.props.form.getFieldValue('username');
    if (!email) {
      message.warning('Informe seu email de login');
      return;
    }

    this.setState({ loading: true });
    setTimeout(async () => {
      const resp = await redefinePassword(email);
      if (resp.success) {
        message.info('Email de redefinição de senha enviado. Verifique sua caixa de emails');
      }
      else {
        message.error(`Ocorreu o seguinte erro ao tentar enviar seu email: ${resp.message}`);
      }
      this.setState({ loading: false });
    }, 1000);
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return;
      }
      this.setState({ loading: true });
      const isOk = await autenticar(values.username, values.password);

      if (isOk) {
        history.push(`/dashboard`);
        history.go();
      } else {
        this.setState({ loading: false });
      }

    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="bodyLogin">
        <div className="centerLoginAdmin">
          <h1 className="titleLoginH1Admin">Bluve</h1>
          <h6 className="titleLoginH6Admin">Admin</h6>

          <Form
            onSubmit={this.handleSubmit}
            className="login-form-admin"
          >
            <Form.Item>
              {getFieldDecorator('username', {
                rules: [{ required: true, message: 'Informe seu email de login' }],
              })(
                <Input
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Email"
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Informe a sua senha de login' }],
              })(
                <Input.Password
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Senha"
                />,
              )}
            </Form.Item>
            <Form.Item>
              <Button type="primary" loading={this.state.loading} htmlType="submit" className="login-form-button-admin">
                Login
              </Button>
            </Form.Item>

            <Form.Item>
              <a className="EsqueciSenhaAdmin" href="# " onClick={this.esqueciSenhaOnClick}>Esqueci minha senha</a>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }


}

export default Form.create()(Login);
