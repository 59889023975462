import DataManager from "./dtm";
import { fbDatabase, fbFirestore } from "../firebase/firebase";

class GerenteDB extends DataManager {
  state = {
    collection: "gerentes",
    orderBy: ["nome", "sobrenome"], // usado na classe pai
  };

  async getTeste() {
    let conversoesSnap = await fbDatabase
      .collection("conversoes")
      .where("dataInicial", ">=", new Date(2021, 5, 1))
      .where("dataInicial", "<", new Date(2021, 5, 2))
      .where("empresaId", "==", "uCgZt31gZfm3pYJoxPQT")
      .get();

    let rankingSnap = await fbDatabase
      .collection("ranking")
      .where("data", ">=", new Date(2021, 5, 1))
      .where("data", "<", new Date(2021, 5, 2))
      .where("empresaId", "==", "uCgZt31gZfm3pYJoxPQT")
      .get();

    let atendimentosSnap = await fbDatabase
      .collection("atendimentos")
      .where("data", ">=", new Date(2021, 5, 1))
      .where("data", "<", new Date(2021, 5, 2))
      .where("empresaId", "==", "uCgZt31gZfm3pYJoxPQT")
      .get();

    // let conversoes = await fbDatabase
    // .collection("empresas")
    // // .where("dataInicial", ">=", new Date(2021, 5, 1))
    // .where("cnpj", '==', '23.631.943/0001-18')
    // .get();

    let conversoes = [];
    conversoesSnap.docs.forEach((conversao) => {
      const data = conversao.data();
      conversoes.push({
        converteu: data.vendeu,
        vendedor: data.vendedor.nome,
        valor: data.valorTotal,
        vendaId: data.vendaId,
        pecas: data.totalPecas,
        data: data.dataInicial.toDate(),
      });
    });

    let rankings = [];
    rankingSnap.docs.forEach((ranking) => {
      const data = ranking.data();
      rankings.push({
        vendedor: data.nome,
        data: data.data.toDate(),
        ...data.atendimentos,
      });
    });

    let atendimentos = [];
    atendimentosSnap.docs.forEach((atendimento) => {
      const data = atendimento.data();
      atendimentos.push({
        data: data.data.toDate(),
        horas: data.horas,
      });
    });
  }

  async getEmpresas(gerenteId, orderBy = null) {

    return new Promise((resolve, reject) => {
      let collectionRef = fbDatabase
        .collection(this.state.collection)
        .doc(gerenteId)
        .collection("empresas");

      if (orderBy) {
        collectionRef = collectionRef.orderBy(orderBy);
      }

      collectionRef.get()
        .then((snapshot) => {

          const empresas = snapshot.docs.map((doc) => {
            const { id: key, razaoSocial } = doc.data();

            return { key, razaoSocial };
          });

          resolve(empresas);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getEmpresasGerente(gerenteId, orderBy) {
    let query = fbDatabase
      .collection(this.state.collection)
      .doc(gerenteId)
      .collection("empresas");

    if (orderBy !== undefined) {
      query = query.orderBy(orderBy);
    }

    const snapshot = await query.get();

    const data = snapshot.docs.map((item) => ({
      key: item.data().id,
      ...item.data(),
    }));

    return data;
  }

  async getByCPF(cpf) {
    const data = await this.getByParamValue("cpf", cpf);
    return data;
  }

  async getByEmail(email) {
    const data = await this.getByParamValue("email", email);
    return data;
  }

  async addEmpresas(gerenteId, empresas) {
    empresas.forEach((item) => {
      fbDatabase
        .collection(this.state.collection)
        .doc(gerenteId)
        .collection("empresas")
        .add({
          id: item.id,
          razaoSocial: item.nome,
        });
    });
  }

  async updateEmpresas(gerenteId, gravarEmpresas) {
    const empresas = await fbDatabase
      .collection(this.state.collection)
      .doc(gerenteId)
      .collection("empresas")
      .get();

    /** X-TUDO */
    let xRef = fbDatabase
      .collection("x-tudo")
      .doc("Bluve-dtmGerenteJSX-GerenteDB-updateEmpresas");
    xRef
      .set(
        {
          collection: "gerentes/empresas",
          lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
          count: fbFirestore.FieldValue.increment(empresas.size),
          lastIncrement: empresas.size,
        },
        { merge: true }
      )
      .catch((error) => console.error(error));

    // ************************************************************
    // Excluo os documentos que eventualmente tenham sido alterados
    // ************************************************************
    empresas.docs.forEach((item) => {
      let canRemove = true;
      gravarEmpresas.forEach((iEmp) => {
        if (iEmp.id === item.data().id) {
          canRemove = false;
        }
      });

      if (canRemove) {
        fbDatabase
          .collection(this.state.collection)
          .doc(gerenteId)
          .collection("empresas")
          .doc(item.id)
          .delete();
      }
    });

    // ********************************************************
    // Adiciono os novos documentos que tenham sido adicionados
    // ********************************************************
    gravarEmpresas.forEach((iEmp) => {
      let canAdd = true;
      empresas.docs.forEach((item) => {
        if (iEmp.id === item.data().id) {
          canAdd = false;
        }
      });

      if (canAdd) {
        fbDatabase
          .collection(this.state.collection)
          .doc(gerenteId)
          .collection("empresas")
          .add({
            id: iEmp.id,
            razaoSocial: iEmp.nome,
          });
      }
    });
  }

  async getGerentesByGestorId(id, ativoFiltro) {
    let snapshot = fbDatabase
      .collection(this.state.collection)
      .where("gestorId", "==", id)
      .where("ativo", "==", !ativoFiltro);

    if (this.state.orderBy.length > 0) {
      for (let index = 0; index < this.state.orderBy.length; index++) {
        const order = this.state.orderBy[index];
        snapshot = snapshot.orderBy(order);
      }
    }

    snapshot = await snapshot.get();

    /** X-TUDO */
    let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmJSX-DataManager-getByGestorId-' + this.state.collection);
    xRef.set({
      collection: this.state.collection,
      lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
      count: fbFirestore.FieldValue.increment(snapshot.size),
      lastIncrement: snapshot.size
    }, { merge: true }).catch(error => console.error(error));

    if (snapshot.empty) {
      return undefined;
    }

    const data = snapshot.docs.map((item) => ({
      key: item.id,
      ...item.data(),
    }));
    return data;
  }

  async updateAtivoField(gerenteId, ativo) {
    await fbDatabase.collection(this.state.collection).doc(gerenteId).update({
      ativo: ativo,
    });
  }
}

const gerenteDB = new GerenteDB();
export default gerenteDB;
