import React from 'react';
import {
  Form,
  Input,
  Tooltip,
  Button,
  Divider,
  Select,
  Icon,
  message,
  Tabs,
} from 'antd';

import { fireBase, KBA_PROJECT } from '../../../firebase/firebase';
import { getOwnerId } from '../../auth/auth-provider';
import { isCNPJ, formatCNPJ, clearCpfFormat } from '../../funcs/utils';
import mensagem from '../../messages/message';
import clienteDB from '../../../dataManager/dtmCliente';
import usuarioDB from '../../../dataManager/dtmUsuario';
import userAdmin from '../../userAdmin/userAdmin';
import Kba4c from './package/src';
import configuracaoDB from '../../../dataManager/dtmConfiguracao';

const { TabPane } = Tabs;
const { Option } = Select;
const kba = new Kba4c(KBA_PROJECT);


class ClienteCRUD extends React.Component {
  state = {
    loading: false,
    confirmDirty: false,
    autoCompleteResult: [],
    selecteds: [],
    showAtivo: 'none',
    data: {
      key: this.props.record.key,
      ativo: 'Sim',
      razaoSocial: '',
      nomeFantasia: '',
      cnpj: '',
      email: '',
      endereco: {
        logradouro: '',
        bairro: '',
        cidade: '',
        uf: '',
        telefone: '',
      }
    },
    treeData: [
      {
        title: 'Compras (Débito & Crédito)',
        value: 'debitoCredito',
        key: 'debitoCredito',
      },
      {
        title: 'Cashback',
        value: 'cashback',
        key: 'cashback',
      },
      {
        title: 'Fidelidade',
        value: 'fidelidade',
        key: 'fidelidade',
      },
    ],
  };

  constructor(props) {
    super(props);
    this.cancelClick = this.cancelClick.bind(this);
    this.selectHandleChange = this.selectHandleChange.bind(this);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return;
      }

      this.setState({ loading: true });

      if (!isCNPJ(values.cnpj)) {
        mensagem.openNotificationWithIcon('error', 'CNPJ inválido', 'O CNPJ informado não está correto.');
        this.setState({ loading: false });
        return;
      }
      values.cnpj = formatCNPJ(values.cnpj);

      // procuro pelo CNPJ para checar se está repetido
      let snapshot = await clienteDB.getByCNPJ(values.cnpj);

      // Verifico se está havendo uma duplicação de CNPJ
      if (snapshot !== undefined) {
        if ((this.props.editMode && snapshot[0].key !== this.props.record.key) ||
          (this.props.editMode && snapshot.length > 1) || (!this.props.editMode)) {
          mensagem.openNotificationWithIcon('error', 'Duplicação', 'O CNPJ informado já foi cadastrado.');
          this.setState({ loading: false });
          return;
        }
      }

      // procuro pelo email para checar se está repetido
      snapshot = await clienteDB.getByEmail(values.email);
      if (snapshot !== undefined) {
        if ((this.props.editMode && snapshot[0].key !== this.props.record.key) ||
          (this.props.editMode && snapshot.length > 1) || (!this.props.editMode)) {
          mensagem.openNotificationWithIcon('error', 'Duplicação', 'O email informado já foi cadastrado.');
          this.setState({ loading: false });
          return;
        }
      }

      const item = {
        ativo: values.ativo.toLocaleLowerCase() === 'sim' ? true : false,
        razaoSocial: values.razaoSocial,
        nomeFantasia: values.nomeFantasia,
        cnpj: values.cnpj,
        email: values.email,
        endereco: {
          logradouro: values.endereco,
          bairro: values.bairro,
          cidade: values.cidade,
          uf: values.uf,
          telefone: values.telefone
        },
      }

      if (!item.ativo) {
        if (!this.props.editMode) {
          mensagem.openNotificationWithIcon('error', 'Ops!', 'Não é possível inativar um cliente ao cadastrá-lo', 3);
          return;
        }

        if (this.props.record.ativo === 'Sim' || this.props.record.ativo === true) {
          let isOk = await mensagem.confirmar('Ao inativar o cliente, todas as lojas dele perderão acesso de login. Confirma?');
          if (!isOk) {
            this.setState({ loading: false });
            return;
          }
        }
      }

      let novoId = '';
      if (!this.props.editMode) {

        // Antes de mais nada, crio um novo login com o email
        // e uma senha aleatória grande o suficiente para não ser adivinhada
        let password = '';
        for (let i = 0; i < 10; i++) {
          password += Math.random().toString(36).substring(2, 12);
        }

        const obj = await fireBase.auth().createUserWithEmailAndPassword(values.email, password);

        if (!obj) {
          message.error(`Erro ao cadastrar o cliente com o email ${values.email}`);
          this.setState({ loading: false });
          return;
        }

        const usuario = {
          uid: obj.user.uid,
          tipo: 'Gestor',
        }

        const rec = await clienteDB.create(usuario.uid, item);
        novoId = rec.id;

        // Salvo o usuário como gestor
        await usuarioDB.add(usuario);

        // Criar registro de configuração
        await configuracaoDB
          .add({
            gestorId: usuario.uid,
            bloquearVendedorForaAtendimento: false,
          });

        // Enviar email de cadastro
        await kba.sendEmailRegistrarion(
          item.nomeFantasia,
          item.email
        )
      } else {
        const isChanged = this.props.record.email !== values.email;
        const podeSalvar = !isChanged || await mensagem.confirmar('Alteração de email detectada. Confirma?');

        if (!podeSalvar) {
          this.setState({ loading: false });
          message.warning('Verifique o email do gestor');
          return;
        }

        if (isChanged) {
          message.info('Aguarde, estamos alterando o email...');
          await userAdmin.changeUserMail(this.props.record.email, values.email);
        }


        let ativo = this.props.record.ativo;
        if (typeof ativo === 'string') {
          ativo = ativo.toLocaleLowerCase() === 'sim' ? true : false;
        }

        await clienteDB.update(this.props.record.key, item);

        const active = values.ativo.toLocaleLowerCase() === 'sim' ? true : false;

        const statusCliente = values.ativo.toLocaleLowerCase() === 'sim' ? true : false;
        const mudouAtivo = this.props.record.ativo !== statusCliente;
        if (mudouAtivo) {
          userAdmin.activeOrDeactiveUser(item.email, active);
        }
      }

      const tipoAtualizacao = this.props.editMode ? 'atualizado' : 'cadastrado';
      mensagem.openNotificationWithIcon('success', 'Perfeito!', `O cliente ${values.nomeFantasia} foi ${tipoAtualizacao} com sucesso`, 3);
      item['key'] = novoId ? novoId : this.props.record.key;
      item['ativoStr'] = values.ativo.toLocaleLowerCase() === 'sim' ? 'Sim' : 'Não';
      this.props.handleOk(item);
      this.setState({ loading: false });
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  componentDidMount() {

    if (this.props.editMode && this.props.record) {
      const data = {
        key: this.props.record.key,
        ativo: (this.props.record.ativo === true || this.props.record.ativo === 'Sim') ? 'sim' : 'nao',
        razaoSocial: this.props.record.razaoSocial,
        nomeFantasia: this.props.record.nomeFantasia,
        cnpj: this.props.record.cnpj,
        email: this.props.record.email,
        endereco: this.props.record.endereco ? this.props.record.endereco : {
          logradouro: '',
          bairro: '',
          cidade: '',
          uf: '',
          telefone: '',
        },
      };

      this.setState({
        data,
        showAtivo: 'block',
      });
    }
  }

  cancelClick() {
    this.props.handleCancel();
  }

  selectHandleChange(value) {
    this.setState({ selecteds: value });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>

        <Tabs defaultActiveKey="geral">
          <TabPane tab="Geral" key="geral">
            <Form.Item
              style={{ display: this.state.showAtivo }}
              label={
                <span>
                  Ativo&nbsp;
                  <Tooltip title={`Esse cliente está ativo?`}>
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('ativo', {
                initialValue: this.state.data.ativo,
                rules: [{ required: true, message: `Informe se o cliente está ativo` }],
              })(

                <Select style={{ width: 120 }}>
                  <Option value="sim">Sim</Option>
                  <Option value="nao">Não</Option>
                </Select>

              )}
            </Form.Item>

            <Form.Item
              ref='txtNome'
              label={
                <span>
                  Razão Social&nbsp;
                  <Tooltip title="Qual é a Razão Social?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('razaoSocial', {
                initialValue: this.state.data.razaoSocial,
                rules: [{ required: true, message: 'Informe a Razão Social', whitespace: true }],
              })(<Input />)}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  Nome Fantasia&nbsp;
                  <Tooltip title="Qual é o Nome Fantasia?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('nomeFantasia', {
                initialValue: this.state.data.nomeFantasia,
                rules: [{ required: true, message: 'Informe o Nome Fantasia', whitespace: true }],
              })(<Input />)}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  CNPJ&nbsp;
                  <Tooltip title="Qual é o CNPJ?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('cnpj', {
                initialValue: this.state.data.cnpj,
                rules: [{ required: true, message: 'Informe o CNPJ', whitespace: false }],
              })(<Input />)}
            </Form.Item>


            <Form.Item label="E-mail" ref="txtEmail">
              {getFieldDecorator('email', {
                initialValue: this.state.data.email,
                normalize: (value) => value.toLowerCase(),
                rules: [
                  {
                    type: 'email',
                    message: 'Email não é válido',
                  },
                  {
                    required: true,
                    message: 'Informe o email',
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (value && value.toUpperCase() === value) {
                        callback("O e-mail não pode conter letras maiúsculas");
                      } else {
                        callback();
                      }
                    },
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </TabPane>

          <TabPane tab="Complemento" key="complemento">
            <Form.Item label="Telefone">
              {getFieldDecorator('telefone', {
                initialValue: this.state.data.endereco.telefone,
                rules: [{ required: false, message: 'Informe o telefone' }],
              })(<Input style={{ width: '100%' }} />)}
            </Form.Item>

            <Form.Item label="Endereço">
              {getFieldDecorator('endereco', {
                initialValue: this.state.data.endereco.logradouro,
                rules: [{ required: true, message: 'Informe o endereço' }],
              })(<Input style={{ width: '100%' }} />)}
            </Form.Item>

            <Form.Item label="Bairro">
              {getFieldDecorator('bairro', {
                initialValue: this.state.data.endereco.bairro,
                rules: [{ required: true, message: 'Informe o bairro' }],
              })(<Input style={{ width: '50%' }} />)}
            </Form.Item>

            <Form.Item label="Cidade">
              {getFieldDecorator('cidade', {
                initialValue: this.state.data.endereco.cidade,
                rules: [{ required: true, message: 'Informe a cidade' }],
              })(<Input style={{ width: '70%' }} />)}
            </Form.Item>

            <Form.Item label="UF">
              {getFieldDecorator('uf', {
                initialValue: this.state.data.endereco.uf,
                rules: [{ required: true, message: 'Informe a UF' }],
              })(<Input style={{ width: '20%' }} />)}
            </Form.Item>

          </TabPane>
        </Tabs>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={this.state.loading}>
            Salvar
          </Button>
          <Divider type="vertical" />
          <Button onClick={this.cancelClick}>
            Cancelar
          </Button>

        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(ClienteCRUD);
