import React, { Component } from 'react';

import {
  Modal,
  Form,
  Input,
  Tooltip,
  Icon,
  Button,
  Divider,
  Checkbox,
  Row,
  Col,
  Switch,
  Tabs,
  Popconfirm,
  message,
  Select,
  Table
} from 'antd';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { v4 as uuidv4 } from 'uuid';
import { isCNPJ, formatCNPJ } from '../../funcs/utils';
import { fireBase, UPDATE_EMAIL_URL } from '../../../firebase/firebase';
import { currentUser } from '../../auth/auth-provider';

import PasswordStrengthBar from 'react-password-strength-bar';
import API from '../../../services/api/api';
import clienteDB from '../../../dataManager/dtmCliente';
import usuarioDB from '../../../dataManager/dtmUsuario';
import configuracaoDB from '../../../dataManager/dtmConfiguracao';
import mensagem from '../../messages/message';

import './EmpresaCRUD.css';


const { confirm } = Modal;
const { TabPane } = Tabs;
const api = new API();
const key = 'updatemessage';
const { Option } = Select;


class EmpresaCRUD extends Component {
  state = {
    activeKey: 'geral',
    bloqueiaFurarFila: false,
    bloqueiaBotaoSalvar: false,
    confirmDirty: false,
    autoCompleteResult: [],
    furarFila: true,
    apiKeyCopiado: false,
    popconfirm: {
      visible: false,
    },
    data: {
      key: this.props.record.key,
      razaoSocial: '',
      nomeFantasia: '',
      cnpj: '',
      email: '',
      endereco: {
        logradouro: '',
        bairro: '',
        cidade: '',
        uf: '',
        telefone: '',
      },
      gestorId: '',
      login: {
        login: '',
        password: '',
        passwordStrength: '',
      },
      integracao: {
        ativo: false,
        key: '',
      },
    },
    setores: [],
    setor: "",
    setorOld: "",
    columns: [
      {
        title: 'Nome do Setor',
        dataIndex: 'setor',
        key: 'setor',
      },
      {
        title: '',
        key: 'action',
        width: '100px',
        render: (text, record) => (
          <span>
            <Tooltip placement="topLeft" title="Excluir">
              <Icon
                type="delete"
                onClick={() => this.deleteSetor(record)}
              />
            </Tooltip>
          </span>
        ),
      }
    ],
  };

  constructor(props) {
    super(props);

    this.cancelClick = this.cancelClick.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
    this.getFurarFilaConfig = this.getFurarFilaConfig.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.changeLoadingStatus = this.changeLoadingStatus.bind(this);
    this.passwordChanged = this.passwordChanged.bind(this);
    this.scoreCheck = this.scoreCheck.bind(this);
    this.evaluationHasBeenExpired = this.evaluationHasBeenExpired.bind(this);

    this.updateSetor = this.updateSetor.bind(this);
    this.editSetor = this.editSetor.bind(this);
    this.deleteSetor = this.deleteSetor.bind(this);
    this.setorEditElement = React.createRef();

  }

  tabChange(activeKey) {
    this.setState({ activeKey });
  }

  changeLoadingStatus(loading) {
    this.setState({ loading });
    if (this.props.onCloseHandle) {
      this.props.onCloseHandle(!loading)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const _this = this;
        mensagem.printFormErrors(err, 3, function (requireds) {
          let activeKey = '';
          if (
            requireds.indexOf('cnpj') >= 0 ||
            requireds.indexOf('razaoSocial') >= 0 ||
            requireds.indexOf('nomeFantasia') >= 0 ||
            requireds.indexOf('email') >= 0
          ) {
            activeKey = 'geral';
          } else {
            activeKey = 'complementos';
          }
          _this.setState({ activeKey });
        });

        return;
      }

      if (!isCNPJ(values.cnpj)) {
        mensagem.openNotificationWithIcon(
          'error',
          'CNPJ inválido',
          'O CNPJ informado não está correto.'
        );
        return;
      }

      this.changeLoadingStatus(true);

      values.cnpj = formatCNPJ(values.cnpj);
      const { gestorId } = this.state.data;

      const item = {
        gestorId,
        ativo: values.ativo.toLocaleLowerCase() === 'sim' ? true : false,
        razaoSocial: values.razaoSocial,
        nomeFantasia: values.nomeFantasia,
        cnpj: values.cnpj,
        email: values.email,
        furarFila: this.state.furarFila,
        endereco: {
          logradouro: values.endereco,
          bairro: values.bairro,
          cidade: values.cidade,
          uf: values.uf,
          telefone: values.telefone,
        },
        login: {
          login: values.login,
          password: values.password,
        },
        gestorDesativado: await this.evaluationHasBeenExpired(gestorId)
      };

      // Atribuição separada, pois no caso de não ter chave de integração
      // definida ocorrerá erro no update do Firebase
      if (this.state.data.integracao) {
        item['integracao'] = this.state.data.integracao;
      }

      const setores = [];
      this.state.setores.forEach(setor => {
        setores.push(setor.setor);
      })

      item.setores = setores;


      let res;
      if (!this.props.editMode) {
        // Cadastrar empresa
        res = await api.cadastrarEmpresa(item);

        if (res.recorded) {
          const usuario = {
            gestorId,
            ativo: true,
            id: res.id,
            lastPasswordUpdate: new Date(),
            tipo: 'Empresa'
          }

          res = await usuarioDB.add(usuario);

          if (!res.recorded && !res.updated) {
            mensagem.openNotificationWithIcon(
              'error',
              'Erro',
              'Erro ao tentar salvar a empresa.'
            );
            return;
          }
        }
      } else {
        // Atualizar empresa
        const changedPassword = this.props.record.password !== values.password;

        if (changedPassword) {
          item.login.ultimaAlteracaoSenha = new Date();
        }

        const isChanged = this.props.record.email !== values.email;

        const confirmou =
          !isChanged || (await this.showConfirmationModal(values.email));

        if (!confirmou) {
          mensagem.openNotificationWithIcon(
            'warning',
            'Verifique o email do gestor'
          );
          this.changeLoadingStatus(false);
          return;
        }
        if (isChanged) {
          mensagem.openNotificationWithIcon(
            'warning',
            'Aguarde, estamos alterando o email...'
          );
          await this.updateEmail(
            this.props.record.email,
            values.email,
            this.props.record.key
          );
        }

        res = await api.atualizarEmpresa(item, this.props.record.key);
      }

      if (!res.recorded) {
        const title = res.duplicated ? 'Duplicação' : 'Erro'
        mensagem.openNotificationWithIcon(
          'error',
          title,
          res.message
        );

        this.changeLoadingStatus(false);
        return;
      } else {
        mensagem.openNotificationWithIcon(
          'success',
          'Perfeito!',
          res.message,
          3
        );

        item['key'] = res.id ?? this.props.record.key;
        item['ativoStr'] = values.ativo.toLocaleLowerCase() === 'sim' ? 'Sim' : 'Não';
        this.props.handleOk(item);
      }
    });
  };

  updateSetor(setor, updateMode) {

    if (!setor) {
      return;
    }

    if (!updateMode) {

      // Prêmio com a mesma pontuação
      let jaExiste = this.state.setores.filter((item) => {
        return item.setor.toLocaleLowerCase() === setor.toLocaleLowerCase()
      });

      if (jaExiste.length > 0) {
        message.error({ content: `Já existe um setor chamado ${setor}.`, key });
        return;
      }
    }

    let setores = this.state.setores;

    if (updateMode) {
      setores.forEach(str => {
        if (str.setor === this.state.setorOld) {
          str.setor = setor;
        }
      })
    } else {
      setores.push({ setor });
    }
    setores.sort((a, b) => (a.setor > b.setor) ? 1 : -1);
    this.setState({ setores });
  }

  editSetor(record) {
    const setorOld = record.setor;
    this.setState({ setorOld });
    this.setorEditElement.current.setorEdit(record)
  }

  async deleteSetor(record) {
    const msg = `Deseja excluir o setor ${record.setor}?`;
    let isOk = await mensagem.confirmar(msg);
    if (!isOk) {
      return;
    }

    const setores = this.state.setores.filter((item) => {
      return item.setor !== record.setor
    });
    this.setState({ setores });
  }

  async evaluationHasBeenExpired(gestorId) {
    const cliente = await clienteDB.getById(gestorId);
    return cliente.length > 0 ? !cliente[0].ativo : false;
  }

  showConfirmationModal(email) {
    return new Promise((resolve) => {
      confirm({
        title: 'Alteração de email',
        icon: (
          <Icon
            type='exclamation'
            style={{ fontSize: '16px', color: '#08c' }}
            theme='outlined'
          />
        ),
        content: 'Confirma alteração do email para: ' + email + '?',
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  }

  async getToken() {
    const token = await fireBase
      .auth()
      .currentUser
      .getIdToken(true)
      .then(function (idToken) {
        return 'bearer ' + idToken;
      })
      .catch(function (error) {
        console.log(error.message);
        return;
      });

    return token;
  }

  async updateEmail(oldEmail, newEmail, key) {
    return new Promise(async (resolve, reject) => {
      const URL = UPDATE_EMAIL_URL;

      const token = await this.getToken();

      const item = {
        headers: {
          'content-type': 'application/json',
          oldemail: oldEmail,
          newemail: newEmail,
          Authorization: token,
          authUserToken: true,
          key: key,
        },
        method: 'POST',
      };
      fetch(URL, item)
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.code === 200) {
            resolve(responseData);
          } else {
            reject(responseData.message);
          }
        });
    });
  }

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  componentDidMount() {
    const { record, editMode } = this.props;
    const { data } = this.state;

    this.getFurarFilaConfig(record.gestorId);

    data.gestorId = record.gestorId;
    this.setState({ data });

    if (editMode && record) {
      let login = record.login;
      login = {
        ...login,
        passwordStrength: login.password
      };

      const setores = [];

      if (record.setores) {
        for (let index = 0; index < record.setores.length; index++) {
          const setor = record.setores[index];
          setores.push({ setor });
        }
      }

      const ativo = this.props.record.ativo !== undefined ? this.props.record.ativo : true;

      this.setState({
        furarFila: record.furarFila,
        setores,
        data: {
          ativo,
          ativoStr: ativo  ? "Sim" : "Não",
          key: record.key,
          razaoSocial: record.razaoSocial,
          nomeFantasia: record.nomeFantasia,
          cnpj: record.cnpj,
          email: record.email,
          endereco: record.endereco,
          login: login,
          integracao: record.integracao,
          gestorId: record.gestorId
        },
      });
    }
  }

  async getFurarFilaConfig(gestorId) {
    const config = await configuracaoDB.getConfiguracao(gestorId);

    const bloqueia =
      config !== undefined ? config[0].bloquearVendedorForaAtendimento : true;

    const furarFila = bloqueia ? false : this.state.furarFila;

    this.setState({ bloqueiaFurarFila: bloqueia, furarFila: furarFila });
  }

  cancelClick() {
    this.props.handleCancel();
  }

  uuidSubstring() {
    let uuid = uuidv4();
    let key = uuid.substr(24);

    return key;
  }

  newAPIKey() {
    let key = this.uuidSubstring();

    this.setState({
      apiKeyCopiado: false,
      data: {
        ...this.state.data,
        integracao: {
          key: key,
          ativo: this.state.data.integracao
            ? this.state.data.integracao.ativo
            : false,
        },
      },
    });
  }

  ativacaoAPIKey(ativo) {
    let key;

    if (!this.state.data.integracao) {
      key = this.uuidSubstring();
    } else {
      key = this.state.data.integracao.key;
    }

    this.setState({
      data: {
        ...this.state.data,
        integracao: {
          key: key,
          ativo: ativo,
        },
      },
    });
  }

  onCheckChange() {
    this.setState({ furarFila: !this.state.furarFila });
  }

  passwordChanged(obj) {
    const data = this.state.data;
    data.login.passwordStrength = obj.target.value;
    this.setState({ data });
  };

  scoreCheck(score) {
    let botao = true;

    if (score >= 2) botao = false;
    this.setState({ bloqueiaBotaoSalvar: botao })
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    const handleVisibleChange = (visible) => {
      if (!visible) {
        this.setState({
          popconfirm: {
            visible: visible,
          },
        });

        return;
      }

      if (!this.state.data.integracao.ativo) {
        onSwitchAPIKey(true); // next step
      } else {
        this.setState({
          popconfirm: {
            visible: visible,
          },
        }); // show the popconfirm
      }
    };

    const onSwitchAPIKey = (ativo, event) => {
      if (ativo) {
        this.ativacaoAPIKey(ativo);
      }
    };

    const desativaIntegracao = () => {
      this.setState({ popconfirm: { visible: false } });
      this.ativacaoAPIKey(false);
    };

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        <Tabs activeKey={this.state.activeKey} onChange={this.tabChange}>
          <TabPane tab='Geral' key='geral'>


          <Form.Item
              label={
                <span>
                  Ativo&nbsp;
                  <Tooltip title="Essa empresa está ativa?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("ativo", {
                initialValue: this.state.data.ativoStr,
                rules: [
                  {
                    required: true,
                    message: "Informe se a empresa está ativa",
                  },
                ],
              })(
                <Select style={{ width: 120 }}>
                  <Option value="sim">Sim</Option>
                  <Option value="nao">Não</Option>
                </Select>
              )}
            </Form.Item>


            <Form.Item
              label={
                <span>
                  CNPJ&nbsp;
                  <Tooltip title='Qual é o CNPJ?'>
                    <Icon type='question-circle-o' />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('cnpj', {
                initialValue: this.state.data.cnpj,
                rules: [
                  {
                    required: true,
                    message: 'Informe o CNJP',
                    whitespace: false,
                  },
                ],
              })(<Input />)}
            </Form.Item>

            <Form.Item
              ref='txtRazaoSocial'
              label={
                <span>
                  Razão Social&nbsp;
                  <Tooltip title='Qual é a Razão Social?'>
                    <Icon type='question-circle-o' />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('razaoSocial', {
                initialValue: this.state.data.razaoSocial,
                rules: [
                  {
                    required: true,
                    message: 'Informe a Razão Social',
                    whitespace: true,
                  },
                ],
              })(<Input />)}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  Nome Fantasia&nbsp;
                  <Tooltip title='Qual é o Nome Fantasia?'>
                    <Icon type='question-circle-o' />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('nomeFantasia', {
                initialValue: this.state.data.nomeFantasia,
                rules: [
                  {
                    required: true,
                    message: 'Informe o Nome Fantasia',
                    whitespace: true,
                  },
                ],
              })(<Input />)}
            </Form.Item>

            
            <Form.Item label="E-mail" ref="txtEmail">
              {getFieldDecorator("email", {
                initialValue: this.state.data.email,
                normalize: (value) => value.toLowerCase(),
                rules: [
                  {
                    type: "email",
                    message: "Email não é válido",
                  },
                  {
                    required: true,
                    message: "Informe o email",
                  },

                  ,
                  {
                    validator: (rule, value, callback) => {
                      if (value && value.toUpperCase() === value) {
                        callback("O e-mail não pode conter letras maiúsculas");
                      } else {
                        callback();
                      }
                    },
                  },

                ],
              })(<Input />)}
            </Form.Item>

          </TabPane>

          <TabPane tab='Endereço' key='endereco'>
            <Form.Item label='Telefone'>
              {getFieldDecorator('telefone', {
                initialValue: this.state.data.endereco.telefone,
                rules: [{ required: false, message: 'Informe o telefone' }],
              })(<Input style={{ width: '50%' }} />)}
            </Form.Item>

            <Form.Item label='Endereço'>
              {getFieldDecorator('endereco', {
                initialValue: this.state.data.endereco.logradouro,
                rules: [{ required: false, message: 'Informe o endereço' }],
              })(<Input style={{ width: '100%' }} />)}
            </Form.Item>

            <Form.Item label='Bairro'>
              {getFieldDecorator('bairro', {
                initialValue: this.state.data.endereco.bairro,
                rules: [{ required: false, message: 'Informe o bairro' }],
              })(<Input style={{ width: '50%' }} />)}
            </Form.Item>

            <Form.Item label='Cidade'>
              {getFieldDecorator('cidade', {
                initialValue: this.state.data.endereco.cidade,
                rules: [{ required: false, message: 'Informe a cidade' }],
              })(<Input style={{ width: '70%' }} />)}
            </Form.Item>

            <Form.Item label='UF'>
              {getFieldDecorator('uf', {
                initialValue: this.state.data.endereco.uf,
                rules: [{ required: false, message: 'Informe a UF' }],
              })(<Input style={{ width: '20%' }} />)}
            </Form.Item>
          </TabPane>

          <TabPane tab='Complementos' key='complementos'>
            <Form.Item
              label={
                <span>
                  Furar Fila?&nbsp;
                  <Tooltip
                    title={this.state.bloqueiaFurarFila ?
                      `Para desbloquear essa opção vá em: Configurações > Integração PDV > Bloquear venda de vendedor fora de atendimento > Selecione 'Não'` :
                      'Se marcado, permite que os vendedores furem a fila no atendimento'
                    }
                  >
                    <Icon type='question-circle-o' />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator(
                'furarFila',
                {}
              )(
                <Checkbox
                  disabled={this.state.bloqueiaFurarFila}
                  checked={this.state.furarFila}
                  onChange={this.onCheckChange}
                />
              )}
            </Form.Item>

            <Form.Item
              label='Chave Integração'
              extra={this.state.apiKeyCopiado ? 'Chave da API copiado.' : null}
            >
              {getFieldDecorator(
                'integracao',
                {}
              )(
                <Row gutter={8}>
                  <Col span={16}>
                    <Input
                      placeholder='Chave Integração'
                      value={
                        this.state.data.integracao
                          ? this.state.data.integracao.key
                          : '- - - - - - -'
                      }
                      addonBefore={
                        this.state.data.integracao ? (
                          <Popconfirm
                            title='Mudança na chave pode paralizar a integração. Confirma alteração?'
                            onConfirm={() => {
                              this.newAPIKey();
                            }}
                            onCancel={() => { }}
                            okText='Sim'
                            cancelText='Não'
                          >
                            <Icon
                              type='reload'
                              onClick={() => {
                                // this.newAPIKey();
                              }}
                            />
                          </Popconfirm>
                        ) : (
                          <Icon
                            type='plus'
                            onClick={() => {
                              this.newAPIKey();
                            }}
                          />
                        )
                      }
                      addonAfter={
                        this.state.data.integracao ? (
                          <CopyToClipboard
                            text={this.state.data.integracao.key}
                            onCopy={() =>
                              this.setState({ apiKeyCopiado: true })
                            }
                          >
                            <Icon type='copy' />
                          </CopyToClipboard>
                        ) : null
                      }
                    />
                  </Col>
                  <Col span={8}>
                    <Popconfirm
                      title='Desativar a chave paralisa a integração com o PDV. Confirma alteração?'
                      visible={this.state.popconfirm.visible}
                      onVisibleChange={handleVisibleChange}
                      onConfirm={() => {
                        desativaIntegracao();
                      }}
                      onCancel={() => {
                        this.setState({ popconfirm: { visible: false } });
                      }}
                      okText='Sim'
                      cancelText='Não'
                    >
                      <Switch
                        checked={
                          this.state.data.integracao
                            ? this.state.data.integracao.ativo
                            : false
                        }
                        checkedChildren={<span>Ativo</span>}
                        unCheckedChildren={<span>Inativo</span>}
                        onChange={onSwitchAPIKey}
                      />
                    </Popconfirm>
                  </Col>
                </Row>
              )}
            </Form.Item>

            <Form.Item label='Login'>
              {getFieldDecorator('login', {
                initialValue: this.state.data.login.login,
                rules: [
                  { required: true, message: 'Informe o login de conexão' },
                ],
              })(<Input style={{ width: '100%' }} />)}
            </Form.Item>

            <Form.Item label='Senha'>
              {getFieldDecorator('password', {
                initialValue: this.state.data.login.password,
                rules: [
                  { required: true, message: 'Informe a senha de conexão' },
                ],
              })(<Input.Password onChange={this.passwordChanged} style={{ width: '60%' }} />)}

              <PasswordStrengthBar
                password={this.state.data.login.passwordStrength}
                scoreWords={['Senha muito fraca', 'Senha fraca', 'Senha razoável', 'Senha boa', 'Senha forte']}
                shortScoreWord={this.state.data.login.passwordStrength ? 'Senha muito curta' : ''}
                minLength={6}
                onChangeScore={(score) => this.scoreCheck(score)}
                style={{ width: '60%' }} />
            </Form.Item>
          </TabPane>


          <TabPane tab="Setores" key="setores">

            <Form.Item>

              <Table className="tableSetor" pagination={{ pageSize: 10, position: 'top' }} title={() =>
                <HeaderSetor
                  updateSetor={this.updateSetor}
                  ref={this.setorEditElement}
                />}
                columns={this.state.columns}
                dataSource={this.state.setores}
                bordered
                loading={this.state.loading}
              />

            </Form.Item>

          </TabPane>




        </Tabs>
        <Form.Item {...tailFormItemLayout}>
          <Button type='primary' htmlType='submit' loading={this.state.loading} disabled={this.state.bloqueiaBotaoSalvar}>
            Salvar
          </Button>
          <Divider type='vertical' />
          <Button onClick={this.cancelClick} disabled={this.state.loading}>Cancelar</Button>
        </Form.Item>
      </Form>
    );
  }
}

class HeaderSetor extends React.Component {
  constructor(props) {
    super(props);
    this.novoSetorElement = React.createRef();
    this.state = {
      visible: false,
    };
    this.novoSetor = this.novoSetor.bind(this);
    this.setorEdit = this.setorEdit.bind(this);
  }

  novoSetor() {
    this.novoSetorElement.current.show(false);
  }

  setorEdit(record) {
    this.novoSetorElement.current.show(true, record);
  }

  render() {
    const novoUsuarioText = 'Novo Setor';
    return (
      <div>
        <NovoSetor
          ref={this.novoSetorElement}
          updateSetor={this.props.updateSetor}
        >
        </NovoSetor>

        <Button disabled={this.props.naoCadastrar} className="btnSuccess" onClick={this.novoSetor}><Icon className="icon" type="plus" /> {novoUsuarioText}</Button>

      </div>
    );
  }
}

class NovoSetor extends React.Component {
  state = {
    visible: false,
    confirmLoading: false,
    editMode: false,
    record: [],
    setor: ''
  }

  constructor(props) {
    super(props);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.setorOnChange = this.setorOnChange.bind(this);
  }

  show(editMode, record) {
    if (!record) {
      record = [];
    }

    this.setState({
      visible: true,
      confirmLoading: false,
      editMode: editMode,
      record: record,
      setor: !editMode ? '' : record.setor
    });
  }

  handleOk() {
    if (this.state.setor === '') {
      message.warn({ content: `Informe o setor`, key });
      return;
    }

    this.props.updateSetor(this.state.setor, this.state.editMode);
    this.setState({ visible: false });
  }

  handleCancel() {
    this.setState({ visible: false });
  }

  setorOnChange(obj) {
    const setor = obj.target.value;
    this.setState({ setor });
  }

  render() {
    return (
      <Modal
        title="Setor"
        visible={this.state.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        <div className="divPontosParaTroca">
          <p>Nome (Após salvar, você não poderá mais alterar)</p>
          <Input
            value={this.state.setor}
            onChange={this.setorOnChange}
            placeholder="Digite o nome do setor"
          >
          </Input>
        </div>

      </Modal>
    );
  }
}

export default Form.create()(EmpresaCRUD);
