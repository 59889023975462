import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { exportToExcel, getGerentes } from './GerenteF';

import {
  Button,
  Checkbox,
  Icon,
  Select,
  Tooltip,
  Input,
  Table,
  notification,
  Modal
} from 'antd';

import PageLayout from '../../components/layout/PageLayout';
import GerenteCRUD from '../../components/cruds/gerentes/GerenteCRUD';
import API from '../../services/api/api';
import clienteDB from '../../dataManager/dtmCliente';
import listObj from '../../components/listSearch/listSearch';
import mensagem from '../../components/messages/message';

import './Gerente.css';
import 'antd/dist/antd.css';


const { Search } = Input;
const { Option } = Select;
const api = new API();
let interval;


class Gerente extends Component {
  state = {
    loading: false,
    isSearching: false,
    blockLoadMore: false,
    checked: undefined,
    gestorId: undefined,
    gerentes: [],
    gerentesOriginal: [],
    gerentesInativos: [],
    gerentesInativosOriginal: []
  }

  constructor(props) {
    super(props);

    this.headerGerenteElement = React.createRef();

    this.applyFilter = this.applyFilter.bind(this);
    this.updateList = this.updateList.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.getCheckboxState = this.getCheckboxState.bind(this);
    this.checkboxUpdate = this.checkboxUpdate.bind(this);
    this.editarGerenteElement = this.editarGerenteElement.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
  }

  async onExportClick() {
    exportToExcel(this.state.gerentes);    
  }

  applyFilter(text) {
    this.setState({ loading: true });

    const {
      gerentesOriginal,
      gerentesInativosOriginal
    } = this.state;

    if (text === '') {
      this.setState({
        gerentes: gerentesOriginal,
        gerentesInativos: gerentesInativosOriginal,
        isSearching: false,
        loading: false
      });

      return;
    }

    const keys = [
      'nome',
      'sobrenome',
      'cpf',
      'ativoStr',
      'email',
      'endereco.telefone',
      'empresas'
    ];

    const ativosResult = listObj.search(gerentesOriginal, text, keys);
    const inativosResult = listObj.search(gerentesInativosOriginal, text, keys);

    this.setState({
      gerentes: ativosResult,
      gerentesInativos: inativosResult,
      isSearching: true,
      loading: false
    });

    if (ativosResult.length === 0 && inativosResult.length === 0) {
      return {
        message: 'Resultado não Encontrado',
        description: 'Sua busca não retornou nenhum resultado. Por favor, redefine sua busca.'
      };
    } else {
      return {
        message: 'Busca finalizada',
        description: 'Caso não encontre o gerente desejado, por favor, refine sua busca.'
      };
    }
  }

  updateList(record) {
    const {
      gerentes,
      gerentesInativos
    } = this.state;

    let registroEncontrado = false;

    this.state.gerentes.forEach((item, index) => {
      if (item.key === record.key) {
        if (record.ativo !== item.ativo) {
          gerentes.splice(index, 1);

          if (gerentesInativos.length) {
            gerentesInativos.push(record);
          }
        } else {
          gerentes[index] = record;
        }

        registroEncontrado = true;
      }
    });

    if (gerentesInativos.length && !registroEncontrado) {
      this.state.gerentesInativos.forEach((item, index) => {
        if (item.key === record.key) {
          if (record.ativo !== item.ativo) {
            gerentesInativos.splice(index, 1);
            gerentes.push(record);
          } else {
            gerentes[index] = record;
          }

          registroEncontrado = true;
        }
      });
    }

    if (!registroEncontrado && record.ativo) {
      gerentes.push(record);
    } else if (gerentesInativos.length && !registroEncontrado) {
      gerentesInativos.push(record);
    }

    this.setState({
      gerentes,
      gerentesInativos,
      gerentesOriginal: gerentes,
      gerentesInativosOriginal: gerentesInativos
    });
  }

  async loadMore() {
    this.setState({ loading: true });

    const {
      gestorId,
      checked,
      gerentes,
      gerentesInativos
    } = this.state;

    const limite = checked ? 25 : 50;
    let dataAtivos = [];
    let dataInativos = [];

    if (gerentes.length) {
      const lastDocIdAtivos = gerentes[gerentes.length - 1].key;
      dataAtivos = await getGerentes(gestorId, true, lastDocIdAtivos, limite);
    }

    if (gerentesInativos.length) {
      const lastDocIdInativos = gerentesInativos[gerentesInativos.length - 1].key;
      dataInativos = await getGerentes(gestorId, false, lastDocIdInativos, limite);
    }

    if (dataAtivos.length === 0 && dataInativos.length === 0) {
      mensagem.avisar('Não existe mais gerentes a serem carregados');
      this.setState({ loading: false, blockLoadMore: true });
      return;
    }

    const newTableAtivos = dataAtivos.length > 0 ? gerentes.concat(dataAtivos) : gerentes;
    const newTableInativos = dataInativos.length > 0 ? gerentesInativos.concat(dataInativos) : gerentesInativos;

    this.setState({
      loading: false,
      gerentes: newTableAtivos,
      gerentesOriginal: newTableAtivos,
      gerentesInativos: newTableInativos,
      gerentesInativosOriginal: newTableInativos
    });
  }

  async onSelectChange(value) {
    this.setState({ loading: true });

    const gerentes = await getGerentes(value, true);

    this.setState({
      gerentes,
      gerentesOriginal: gerentes,
      gerentesInativos: [],
      gerentesInativosOriginal: [],
      gestorId: value,
      loading: false,
      isSearching: false,
      blockLoadMore: false,
      checked: false
    });
  }

  getCheckboxState(checked) {
    this.setState({ checked });
    this.checkboxUpdate();
  }

  async checkboxUpdate() {
    this.setState({ loading: true });

    const {
      checked,
      gestorId,
      gerentesInativosOriginal
    } = this.state;

    if (checked && gerentesInativosOriginal.length === 0) {
      const gerentesInativos = await getGerentes(gestorId, false);

      this.setState({
        gerentesInativos,
        gerentesInativosOriginal: gerentesInativos,
        blockLoadMore: false
      });
    }

    this.setState({ loading: false });
  }

  async editarGerenteElement(record) {
    this.headerGerenteElement.current.editarGerente(record);
  }

  render() {
    const columns = [
      {
        title: 'Nome',
        dataIndex: 'nome',
        key: 'nome',
        editable: false,
      },
      {
        title: 'Sobrenome',
        dataIndex: 'sobrenome',
        key: 'sobrenome',
      },
      {
        title: 'CPF',
        dataIndex: 'cpf',
        key: 'cpf',
      },
      {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Telefone',
        dataIndex: 'endereco.telefone',
        key: 'telefone',
      },
      {
        title: 'Ativo?',
        dataIndex: 'ativoStr',
        key: 'ativo'
      },
      {
        title: '',
        width: 'auto',
        key: 'action',
        render: (text, record) => (
          <span>
            <Tooltip placement='topLeft' title='Editar Cadastro'>
              <a onClick={() => this.editarGerenteElement(record)}>
                <Icon type='edit' />
              </a>
            </Tooltip>
          </span>
        ),
      },
    ];

    return (
      <PageLayout selectItem={'gerentes'}>
        <Table
          title={() => (
            <HeaderGerente
              ref={this.headerGerenteElement}
              applyFilter={this.applyFilter}
              updateList={this.updateList}
              getCheckboxState={this.getCheckboxState}
              onSelectChange={this.onSelectChange}
              loading={this.state.loading}
              totalGerentes={this.state.gerentes.length}
              onExportClick={this.onExportClick}
            />
          )}

          footer={() => (
            <div className='footerContainer'>
              <Button
                type='primary'
                loading={this.state.loading}

                disabled={
                  this.state.blockLoadMore ||
                  this.state.isSearching ||
                  !this.state.gestorId
                }

                onClick={this.loadMore}
              >
                Carregar mais Gerentes
              </Button>

              <div style={{ marginTop: '0.625rem' }}>
                Quantidade de Gerentes: {this.state.gerentes.length + (this.state.checked ? this.state.gerentesInativosOriginal.length : 0)}
              </div>
            </div>
          )
          }

          dataSource={
            this.state.checked || this.state.isSearching ?
              this.state.gerentes.concat(this.state.gerentesInativos) :
              this.state.gerentes
          }

          columns={columns}
          loading={this.state.loading}
          pagination={false}
        />
      </PageLayout>
    )
  }
}

class HeaderGerente extends Component {
  state = {
    visible: false,
    checked: false,
    gestorId: undefined,
    options: [],
  }

  constructor(props) {
    super(props);

    this.novoGerenteElement = React.createRef();

    this.loadOptions = this.loadOptions.bind(this);
    this.novoGerente = this.novoGerente.bind(this);
    this.editarGerente = this.editarGerente.bind(this);
    this.filterTable = this.filterTable.bind(this);
    this.resetTime = this.resetTime.bind(this);
    this.onCheck = this.onCheck.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
  }

  componentDidMount() {
    this.loadOptions();
  }

  async onExportClick() {
    this.props.onExportClick();
  }

  async loadOptions() {
    const clientes = await clienteDB.get(true);
    const options = [];

    for (let i = 0; i < clientes.length; i++) {
      const {
        key,
        nomeFantasia,
        email
      } = clientes[i];

      options.push(
        <Option key={i} value={key}>
          {`${nomeFantasia} | ${email}`}
        </Option>
      );
    }

    this.setState({ options });
  }

  novoGerente() {
    const { gestorId } = this.state;
    const record = { gestorId };

    this.props.applyFilter('');
    this.novoGerenteElement.current.show(false, record);
  }

  editarGerente(record) {
    this.props.applyFilter('');
    this.novoGerenteElement.current.show(true, record);
  }

  async filterTable(obj) {
    clearInterval(interval);

    const text = typeof obj === 'object' ? obj.target.value : obj;

    const searchWarning = await this.props.applyFilter(text);

    if (searchWarning) {
      notification.open(searchWarning);
    }
  }

  async resetTime(obj) {
    clearInterval(interval);
    interval = setInterval(this.filterTable, 3000, obj.target.value);
  }

  async onSelectChange(value) {
    this.setState({ gestorId: value, checked: false });
    this.props.onSelectChange(value);
  }

  async onCheck(e) {
    await this.setState({ checked: e.target.checked });
    this.props.getCheckboxState(this.state.checked);
  }

  render() {
    return (
      <div className='headerGerente'>
        <NovoGerente
          ref={this.novoGerenteElement}
          updateList={this.props.updateList}
        />

        <div className='selectCliente'>
          <label>
            Cliente:
          </label>

          <Select
            showSearch
            placeholder='Selecionar o cliente'
            optionFilterProp='children'

            value={this.state.gestorId}
            onChange={this.onSelectChange}

            style={{ marginLeft: '0.625rem', width: '100%' }}
          >
            {this.state.options}
          </Select>
        </div>

        <Button
          type='primary'

          onClick={this.novoGerente}
          disabled={!this.state.gestorId}
          style={{ marginLeft: '0.625rem', width: '500px'}}
        >
          <Icon className='icon' type='plus' />
          Novo Gerente
        </Button>

        <Search
          allowClear
          placeholder='Procurar Gerente'

          onSearch={this.filterTable}
          onChange={this.resetTime}
          disabled={!this.state.gestorId}

          style={{ marginLeft: '0.625rem', width: '15%' }}
        />

        <Checkbox
          checked={this.state.checked}
          onChange={this.onCheck}
          disabled={!this.state.gestorId}

          style={{ marginLeft: '0.625rem' }}
        >
          Listar Inativos
        </Checkbox>

        <br></br>
        <br></br>
        <Button
          className='excel-button-v2'
          disabled={this.props.loading || this.props.totalGerentes === 0}
          onClick={this.onExportClick}
          style={{ marginLeft: '1rem', width: '300px' }}
        >
          Exportar para Excel
        </Button>
      </div>
    )
  }
}

class NovoGerente extends Component {
  state = {
    record: [],
    empresas: [],
    visible: false,
    confirmLoading: false,
    editMode: false
  }

  constructor(props) {
    super(props);

    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  async show(editMode, record) {
    let empresas = [];

    if (!record) {
      record = [];
    } else {
      empresas = await api.getEmpresas(record.gestorId);
      empresas = empresas.filter(empresa => empresa.ativo === true);
    }

    this.setState({
      editMode,
      empresas,
      record,
      visible: true,
      confirmLoading: false,
    });
  }

  handleOk(record) {
    this.props.updateList(record);
    this.setState({ visible: false });
  }

  async handleCancel() {
    if (!(await mensagem.confirmar('Cancelar alterações?'))) return;
    this.setState({ visible: false });
  }

  render() {
    return (
      <Modal
        title='Gerente'

        destroyOnClose={true}
        centered={true}
        footer={null}
        closable={false}
        visible={this.state.visible}
        confirmLoading={this.state.confirmLoading}

        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        <GerenteCRUD
          editMode={this.state.editMode}
          record={this.state.record}
          empresas={this.state.empresas}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
        />
      </Modal>
    )
  }
}

export default withRouter(Gerente);
