import API from '../../services/api/api';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';


const api = new API();


export async function getGerentes(gestorId, ativo, lastDocId, limite) {
  let gerentes = [];
  limite = limite ?? 100;

  gerentes = await api.getGerentes(gestorId, ativo, limite, lastDocId);

  return gerentes;
}

export async function exportToExcel(gerentes) {
  debugger;

  const infoExcel = [];  

  infoExcel.push(['Nome Completo', 'CPF', 'e-mail', 'Telefone', 'Ativo']);
  gerentes.forEach((item) => {
    infoExcel.push([
      item.nome + ' ' + item.sobrenome,
      item.cpf,
      item.email,
      item.telefone,
      item.ativoStr,
    ]);
  });

  const fileName = `Gerentes`;
  await salvarXlsx(infoExcel, fileName);
}

export async function salvarXlsx(infoExcel, fileName) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const worksheet = XLSX.utils.aoa_to_sheet(infoExcel);
  worksheet['!cols'] = new Array(8).fill({ width: 20 });

  const woorkbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

  const excelBuffer = XLSX.write(woorkbook, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(data, fileName);
}


