import ls from 'local-storage'
import vertteStorage from '../localstorage/localstorage';


export function authenticate(user) {
    ls.set('user', user);
}

export function logout() {
    ls.set('user', null);
    ls.set('bluve-admin--user-type', null);
    ls.set('bluve-admin--user-col', null);
    vertteStorage.clearAll();
}

export function currentUserCol() {
    let user = ls.get('bluve-admin--user-col');
    return user;
}


export function currentUser() {
    let user = ls.get('user');
    return user;
}

export function isLoggedIn() {
    const user = currentUser();
    return user !== null && user !== undefined;
}

export function getOwnerId() {
    const user = currentUser();
    const userType = getUserType();
    if (userType === 'Gestor') {
        // Quando o usuário for um gestor, verifico se ele é o owner
        // checando se tem o user collection. O user collection indica que é um gestor
        // e não o owner
        const userCol = currentUserCol();
        if (userCol) {
            return userCol.ownerId;
        }
        return user.uid;
    }
    let gestorId = ls.get('bluve-admin--user-gestorId');
    if (!gestorId) {
        const usuario = ls.get('bluve-admin--user-col');
        if (usuario) {
            gestorId = usuario.ownerId;
        }
        else {
            return undefined;
        }
    }
    return gestorId;
}

export function getUserType() {
    return ls.get('bluve-admin--user-type');
}

export function getGestorId() {
    const user = currentUser();
    const userType = getUserType();
    if (userType === 'Gestor') {
      return user.uid;
    }
    const gestorId = ls.get('bluve-user-gestorId');
    return gestorId;
  }

export function getGerenteId() {
    return ls.get('bluve-admin--user-gerenteId');
}

export function isGestor() {
    return getUserType() === 'Gestor';
}

export function isAdministrador() {
    return getUserType() === 'Administrador';
}
