import { ROUTER_API_BLUVE } from '../../firebase/firebase';
import { fireBase } from '../../firebase/firebase';
import axios from 'axios';

const instance = axios.create({
  baseURL: ROUTER_API_BLUVE,
  headers: {
    'Content-Type': 'application/json',
    'authUserToken': true,
  },
});

const event = new Event('tokenAvailable');

fireBase
  .auth()
  .onAuthStateChanged(async user => {
    if (user) {
      const { uid, refreshToken } = user;
      const idToken = await user.getIdToken(true);

      instance.defaults.headers['uid'] = uid;
      instance.defaults.headers['refreshToken'] = refreshToken;
      instance.defaults.headers['authorization'] = `bearer ${idToken}`;

      document.dispatchEvent(event);
    };
  });

export default instance;
