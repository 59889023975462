import React from "react";
import { Switch, Route, Redirect, withRouter } from "react-router";
import { logout } from './components/auth/auth-provider';
import { fireBase } from "./firebase/firebase";
import ls from 'local-storage'

import Cliente from "./pages/cliente/Cliente";
import Dashboard from "./pages/dashboard/Dashboard"
import Login from "./pages/login/Login";
import Configuracao from "./pages/configuracoes/Configuracao";
import Empresa from "./pages/empresa/Empresa";
import Gerente from "./pages/gerente/Gerente";
import Vendedor from "./pages/vendedor/Vendedor";

class Routes extends React.Component {
  UNSAFE_componentWillMount() {
    fireBase.auth().onAuthStateChanged(user => {
      if (!user && this.props.location.pathname !== "/login"
        && this.props.location.pathname !== "/saldo"
        && this.props.location.pathname !== "/saldo-cartao") {
        logout();
        this.props.history.push("/login");
      }
    });
  }

  render() {
    return (

      <div>
        <Switch>

          {/* Telas que precisam estar logado */}

          <PrivateRoute exact path="/dashboard">
            <Dashboard />
          </PrivateRoute>

          <PrivateRoute exact path="/configuracoes">
            <Configuracao />
          </PrivateRoute>


          {/* CRUDS */}

          <PrivateRoute exact path="/clientes">
            <Cliente />
          </PrivateRoute>


          <PrivateRoute exact path="/convenio-usuarios">
            <Cliente />
          </PrivateRoute>

          <PrivateRoute exact path="/empresas">
            <Empresa />
          </PrivateRoute>

          <PrivateRoute exact path="/gerentes">
            <Gerente />
          </PrivateRoute>

          <PrivateRoute exact path="/vendedores">
            <Vendedor />
          </PrivateRoute>


          {/*Relatórios */}


          <Route path="/login" component={Login} />
          <Redirect from="*" to="/dashboard" />
        </Switch>
      </div>
    );
  }
}

export default withRouter(Routes);


function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        const user = ls.get('user');

        if (user) {
          return children;
        }

        return <Redirect
          to={{
            pathname: "/login",
            state: { from: location }
          }}
        />
      }}
    />
  );
}
