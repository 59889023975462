import API from './services/api/api.js';


const api = new API();

export default class Kba4c {
    constructor(project) {
        let link = '';
        let projectName = '';

        switch (project) {
            case 'bluve':
                link = 'https://bluve.com.br/primeiro-acesso';
                break;

            case 'bluve-dev':
                link = 'https://dev.bluve.com.br/primeiro-acesso';
                break;

            case 'moub':
                link = 'https://app.moub.com.br/primeiro-acesso';
                break;

            case 'moub-conv':
                link = 'https://mais.reginasupermercados.com.br/primeiro-acesso';
                break;

            case 'moub-dev':
                link = 'https://app.dev.moub.com.br/primeiro-acesso';
                break;
        }

        if (project.includes('bluve')) {
            projectName = 'Bluve';
        } else if (project.includes('moub')) {
            projectName = project === 'moub-conv' ?
                'Regina+' :
                'Moub';
        }

        this.project = project;
        this.link = link;
        this.projectName = projectName;
    }

    async createPassword(password, id) {
        const { token } = await api.createPassword(password, id, this.project);
        return token;
    }

    async getAddresses(id) {
        const { enderecos } = await api.getAddresses(id);
        return enderecos;
    }

    async getCensoredEmail(id) {
        const { email } = await api.getCensoredEmail(id);
        return email;
    }

    async getUser(value) {
        const historico = await api.getHistorico(value);
        let bloqueado = false;
        let ativo = true;
        let docId;

        if (!historico.id) {
            const { id } = await api.saveHistorico(value, this.project);
            docId = id;
        } else {
            docId = historico.id;
            bloqueado = historico.bloqueado;
            ativo = historico.ativo;
        }

        return { docId, ativo, bloqueado };
    }

    async validateAddress(endereco, id) {
        const res = await api.validateAddress(endereco, id);
        return res;
    }

    async validateEmail(email, id) {
        const res = await api.validateEmail(email, id);
        return res;
    }

    async validateAccessToken(token, id) {
        const res = await api.validateAccessToken(token, id);
        return res;
    }

    async sendAccessToken(email) {
        await api.sendAccessToken(email, this.project, this.projectName);
    }

    async sendEmailRegistrarion(empresa, email) {
        await api.sendEmailRegistrarion(empresa, email, this.projectName, this.link);
    }
}
