import React from 'react'
import { withRouter } from 'react-router'
import { Layout, Menu, Dropdown, Divider, Avatar, Button, Tooltip } from 'antd';
import history from '../../history';
import { currentUser, currentUserCol, logout } from '../auth/auth-provider';
import { fireBase, fbDatabase } from '../../firebase/firebase';
import './themes/LayoutCSS';
import './PageLayout.css';


const { Header, Content } = Layout;
const { SubMenu } = Menu;


class PageLayout extends React.Component {

  state = {
    collapsed: false,
    friendlyName: '',
    classLogo: 'labelLogoShow',
    classLabelFooter: 'footerVertteLabelNormal',
    photoURL: '',
    userType: '4C Admin',
    permissoes: {
      clientes: true,
    },
    logoClass: 'logoLayoutMaster'
  };

  constructor(props) {
    super(props);
    this.onMenuClick = this.onMenuClick.bind(this);
  }

  async componentDidMount() {
    const user = currentUser();
    const userType = '4C Admin';
    let userCol = currentUserCol();

    const email = user.email.substring(0, user.email.indexOf("@"));
    let friendlyName = user.displayName ? `${user.displayName}` : `${email}`;
    if (user.displayName) {
      friendlyName = friendlyName.split(' ')[0].toLocaleLowerCase().replace(/\b\w/g, l => l.toUpperCase());
    }

    this.setState({
      friendlyName,
      photoURL: user.photoURL ? user.photoURL : 'https://storage.googleapis.com/bluve-bucket/base_img_avatar.png',
    });

    // Controle de acesso
    const { uid } = user;

    fbDatabase
      .collection('x-controle-sessao')
      .doc(uid)
      .onSnapshot(async (doc) => {
        const { refreshToken } = currentUser().stsTokenManager;
        const { lastRefreshToken } = doc.data();

        if (lastRefreshToken !== refreshToken) {
//          logout();

          // await fireBase.auth().signOut();

          // history.push('/login/?sessionRevoked=true');
          // history.go();
        }
      });
  }

  onCollapse = collapsed => {
    let classLogo = 'labelLogoShow';
    let classLabelFooter = 'footerVertteLabelNormal';
    if (collapsed) {
      classLogo = 'labelLogoHidden';
      classLabelFooter = 'footerVertteLabelCollapsed'
    }
    this.setState({ classLabelFooter, classLogo, collapsed });
  };

  onMenuClick(item) {
    // Para que o usuário veja o item ficar azul antes do redirect.
    // Não remover
    setTimeout(() => {
      history.push(`/${item.key}`);
      history.go();
    }, 300);
  }

  handleMenuClick(e) {
    if (e.key === 'menuSair') {
      logout();
      history.push('/login');
      history.go();
    }
  }

  render() {
    const menu = (
      <Profile userType={this.state.userType}></Profile>
    );

    return (
      <React.Fragment>
        <Layout>
          <Layout style={{ height: "100vh" }}>
            <Header style={{
              padding: 0,
              display: 'flex',
              height: '6.25rem',
              backgroundColor: '#143361'
            }}>

              <div className="logo">
                <div className={this.state.logoClass}>
                  <img alt="Bluve"></img>
                </div>
              </div>

              <Menu
                defaultSelectedKeys={[this.props.selectItem]}
                defaultOpenKeys={[this.props.openMenu]}
                onClick={this.onMenuClick}
                theme="dark"
                mode="horizontal"
                style={{
                  fontWeight: 600,
                  fontSize: '1rem',
                  backgroundColor: 'transparent',
                  lineHeight: '6.25rem',
                  width: '70%',
                  marginRight: 'auto'
                }}>

                <Menu.Item key="dashboard">
                  <span>Dashboard</span>
                </Menu.Item>

                <Menu.Item key="clientes">
                  <span>Clientes</span>
                </Menu.Item>

                <SubMenu
                  key='crudMenu'
                  title={
                    <span>
                      <span>Cadastros</span>
                    </span>
                  }
                >
                  <Menu.Item key="empresas">
                    Empresas
                  </Menu.Item>

                  <Menu.Item key="gerentes">
                    Gerentes
                  </Menu.Item>

                  <Menu.Item key="vendedores">
                    Vendedores
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="relatorios"
                  title={
                    <span>
                      <span>Relatórios</span>
                    </span>
                  }>
                </SubMenu>
              </Menu>

              <Dropdown overlay={menu} trigger={['click']}>
                <div>
                  <Avatar className="profileAvatar" size={44} src={this.state.photoURL}
                    style={{ margin: '1.75rem 1.25rem' }} />
                </div>
              </Dropdown>
            </Header>

            <Content style={{ margin: '0.625rem' }}>
              <div
                style={{
                  background: '#fff',
                  minHeight: '100%',
                  padding: '1.25rem',
                  borderRadius: '0.75rem'
                }}
              >
                {this.props.children}
              </div>
            </Content>
          </Layout>
        </Layout>
      </React.Fragment>
    )
  }
}

class Profile extends React.Component {

  state = {
    photoUrl: '',
    userType: '',
    userName: '',
    userEmail: '',
  }

  abbreviate(name) {
    var splitName = name.split(" ");
    if (splitName.length >= 3) {
      for (var i = 1; i < splitName.length - 1; i++) {
        splitName[i] = splitName[i].charAt(0) + '.';
      }
    }
    return splitName.join(" ")
  }

  UNSAFE_componentWillMount() {
    const user = currentUser();
    let userType = this.props.userType ? this.props.userType.replace('AdministradorUsuario', 'Usuário Administrador') : '';
    if (user.displayName) {
      user.displayName = this.abbreviate(user.displayName);
      user.displayName = user.displayName.toLocaleLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
    }
    this.setState({
      photoURL: user.photoURL ? user.photoURL : 'https://storage.googleapis.com/bluve-bucket/base_img_avatar.png',
      userType,
      userName: user.displayName ? user.displayName : user.email.substring(0, user.email.indexOf("@")),
      userEmail: user.email,
      versao: '0.1.0 | build 1.2021-05-19',
    });
  }

  btnSairClick() {
    logout();
    history.push('/login');
    history.go();
  }

  btnPerfilClick() {
    history.push('/perfil');
    history.go();
  }

  render() {
    return (
      <div className="card-profile">
        <img className="img-profile" src={this.state.photoURL} alt={this.state.userName} />
        <h1>{this.state.userName}</h1>
        <p className="title-profile">{this.state.userType}</p>

        <Tooltip placement="top" title={this.state.userEmail}>
          <p className="pspan">{this.state.userEmail} </p>
        </Tooltip>

        <Divider></Divider>

        <p><Button type="primary" className="buttonProfile" onClick={this.btnPerfilClick}>Perfil</Button></p>
        <p><Button className="buttonProfileExit" onClick={this.btnSairClick}>Sair</Button></p>
      </div>
    )
  }
}

export default withRouter(PageLayout);



