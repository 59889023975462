import DataManager from "./dtm";
import { fbDatabase } from "../firebase/firebase";


class UsuarioDB extends DataManager {

    state = {
        collection: 'usuarios',
        orderBy: [''], // usado na classe pai
    }


    async getByUserId(uid) {
        let snapshot = await fbDatabase.collection(this.state.collection)
            .where("uid", "==", uid).get();

        if (snapshot.empty) {
            return undefined;
        }

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
        }));

        return data[0];
    }

    async getByUserType(userType) {
        let snapshot = await fbDatabase.collection(this.state.collection)
            .where("tipo", "==", userType).get();

        if (snapshot.empty) {
            return undefined;
        }

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
        }));

        return data;
    }
};

const usuarioDB = new UsuarioDB();
export default usuarioDB;