import { Component } from "react";
import { fbDatabase } from "../firebase/firebase";


class PrivateMethods extends Component {
  async getByParamValue(collection, param, value) {
    const snapshot = await fbDatabase
      .collection(collection)
      .where(param, "==", value)
      .get();

    if (snapshot.empty === true) {
      return undefined;
    }

    const data = snapshot.docs.map((item) => ({
      key: item.id,
      ...item.data(),
    }));
    return data;
  }
}

export default class DataManager extends Component {
  state = {
    collection: "",
    orderBy: [],
  };

  formatarMoeda(valor) {
    valor = parseFloat(valor).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
    return valor;
  }

  async getById(id) {
    let snapshot = await fbDatabase
      .collection(this.state.collection)
      .doc(id)
      .get();
    if (!snapshot.exists) {
      return undefined;
    }

    return {
      key: snapshot.id,
      ...snapshot.data(),
    };
  }

  async getByOwnerId(id) {
    let snapshot = fbDatabase
      .collection(this.state.collection)
      .where("ownerId", "==", id);

    if (this.state.orderBy.length > 0) {
      for (let index = 0; index < this.state.orderBy.length; index++) {
        const order = this.state.orderBy[index];
        snapshot = snapshot.orderBy(order);
      }
    }

    snapshot = await snapshot.get();
    if (snapshot.empty) {
      return undefined;
    }

    const data = snapshot.docs.map((item) => ({
      key: item.id,
      ...item.data(),
    }));
    return data;
  }

  async create(id, item) {
    return new Promise(async (resolve, reject) => {
      await fbDatabase
        .collection(this.state.collection)
        .doc(id)
        .set({
          ...item,
        })
        .then((res) => {
          resolve({
            recorded: true,
            id,
          });
        })
        .catch((err) => {
          console.log(err);
          reject({
            recorded: false,
            err,
          });
        });
    });
  }

  async add(item) {
    return new Promise(async (resolve, reject) => {
      await fbDatabase
        .collection(this.state.collection)
        .add({
          ...item,
        })
        .then((res) => {
          resolve({
            recorded: true,
            id: res.id,
          });
        })
        .catch((err) => {
          console.log(err);
          reject({
            recorded: false,
            err,
          });
        });
    });
  }

  async update(key, item) {
    return new Promise((resolve, reject) => {
      fbDatabase
        .collection(this.state.collection)
        .doc(key)
        .update({
          ...item,
        })
        .then((res) => {
          resolve({
            updated: true,
            message: "Ok",
          });
        })
        .catch((err) => {
          console.log(err);
          reject({
            updated: false,
            message: "Erro",
            err,
          });
        });
    });
  }

  async getByParamValue(param, value) {
    const privateMethods = new PrivateMethods();
    const data = await privateMethods.getByParamValue(
      this.state.collection,
      param,
      value
    );
    return data;
  }
}
